import type { IProps } from '../FreezePopup.vue'
import type { TronRes } from '~/utils/tron-v2'
import { tron } from '~/utils/tron-v2'
import { useResource } from '~/composables/tron'
import { useMultiSign } from '~/pages-components/c2c/hooks/useMultiSign'
import { useSellProvider } from '~/pages-components/c2c/hooks/useSell'
import { formatTronErrorMessage } from '~/utils/tron-v2/tronError'

const { resourceType: ResourceType } = useResource()
export function useFreeze(props: IProps) {
  const { orderDetail } = props.detail
  const isMultiSign = computed(() => !!props?.detail.multiAddress)
  const loading = useBoolean()
  const freezeResult = ref(false)
  const modalContent = ref('')
  const { onValidatePower, info } = useMultiSign()!
  const {
    remaineResource,
    balance,
  } = useSellProvider(isMultiSign, toRef(props.detail, 'multiAddress'))

  const { account: accountCurrent, refetch } = tronComp.useAccountCurrent()
  const { account: accountMulti, refetch: multiRefetch } = tronComp.useAccount(
    toRef(props.detail, 'multiAddress'),
    toRef(accountCurrent, 'chainId'),
  )

  const title = computed(() => {
    const map: { [kye: string]: string } = {
      '1_0': $t('RRvYluqPaPRe7eGmbayQY'),
      '1_1': $t('jEGkLIlIEyQ-oCW7UVmAB'),
      '0_0': $t('GBYT0K-9f4v2Bl8fZvbsB'),
      '0_1': $t('n5OmlOh-IeEaUjNkslgT2'),
    }
    const key = `${toValue(isMultiSign) ? 1 : 0}_${props?.detail.mode}`
    return map[key]
  })

  const maxTRX = computed(() => {
    const trx = ((balance.value ?? 0) / 1000000) - 1
    return trx > 0 ? trx : 0
  })

  const setMaxTrx = () => {
    if (!maxTRX.value)
      return
    formData.amount = Math.floor(maxTRX.value)
    onChange(formData.amount)
  }

  const resourceType = computed(() =>
    orderDetail?.resource_type ? 'ENERGY' : 'BANDWIDTH',
  )

  const freezeInfo = reactive({
    availableResource: 0,
    needResource: props.detail.sellAmount,
  })

  const formData = reactive({
    amount: undefined,
    resource: 'ENERGY' as 'BANDWIDTH' | 'ENERGY',
  })

  const needExchange = computed(() => {
    const remaine = toValue(remaineResource)[resourceType.value]
    const result
      = freezeInfo.needResource - remaine - freezeInfo.availableResource

    return result > 0 ? result : 0
  })

  const onChange = (e: any) => {
    const { resource } = accountCurrent
    const cost
      = resourceType.value === 'ENERGY'
        ? resource?.energyUnitPrice
        : resource?.netUnitPrice
    freezeInfo.availableResource = Math.floor(e * cost)
  }

  function validator() {
    return new Promise((resolve, reject) => {
      if (!formData.amount) {
        aMessage.info($t('APfc3MeEIDjqXLApadbAz'))
        reject()
      }

      if ((formData.amount ?? 0) > maxTRX.value) {
        aMessage.error(
          `${$t('38ytZ0y25yoOxKMOvq1o8')} ${formatAmount(maxTRX.value, 0)} TRX ${$t('OSICXBRWyZAISpFNxEdll')}`,
        )
        reject()
      }

      resolve(true)
    })
  }

  const onSubmit = async () => {
    try {
      await validator()

      onValidatePower(isMultiSign, 54)

      loading.setTrue()

      let params: any = {
        amount: (formData.amount ?? 0) * 10 ** 6,
        resourceType: toValue(resourceType),
        address: accountCurrent.address,
      }
      let res = null
      if (toValue(isMultiSign)) {
        params = {
          ...params,
          address: accountMulti.address,
          isOwnerPermission: info.isOwnerPermission,
          permissionId: info.permissionId,
        }
        res = await tron.freezeBalanceV2ByMultiSign(params)
      }
      else {
        res = await tron.freezeBalanceV2(params)
      }

      tron.fetchCanDelegatedMaxSize(accountCurrent.address, 0 as TronRes)
      tron.fetchCanDelegatedMaxSize(accountCurrent.address, 1 as TronRes)

      if (res) {
        await refetch()
        await multiRefetch()
        const typeTxt = params.resourceType === 'ENERGY' ? $t('Mqe_hlD_yGPsAMB9oaO1D') : $t('IgN1EoVRFTDyCVLbFG-oT')
        modalContent.value = `${$t('JVJZwWrDZaWCpXUvy7eYn', { amount: formatAmount(params.amount * 10 ** -6, 0) })}
            ${$t('NKsQg2au3bN_T6s8bA0tY', { typeTxt })}
            ${formatAmount(freezeInfo.availableResource, 0)}`
        freezeResult.value = true
      }
      else {
        aMessage.error($t('vXcbCo5Z9Xx0PhQvMopAR'))
      }
      loading.setFalse()
    }
    catch (e) {
      loading.setFalse()
      if (typeof e === 'string') {
        if (e.includes('Confirmation declined by user'))
          aMessage.error($t('Hk5eWgjl88eEeU0VPuaRz'))

        else if (e.includes('but it is not contained of permission'))
          aMessage.error($t('XaVJJSbJsBZJUCo6UjiOh'))

        else if (e.includes('frozenBalance must be more than 1TRX'))
          aMessage.error($t('xIc755xgV4BzHc55RSuf'))

        else if (e.includes('TRANSACTION_EXPIRATION_ERROR'))
          aMessage.error($t('iEe52eGjGSr0BiehkAtdb'))
        else
          aMessage.error(e)
      }

      if (e instanceof Error) {
        const msg = formatTronErrorMessage(e)
        aMessage.error(msg)
      }
    }
  }

  return {
    isMultiSign,
    title,
    maxTRX,
    setMaxTrx,
    freezeInfo,
    formData,
    needExchange,
    onChange,
    onSubmit,
    resourceType,
    loading,
    remaineResource,
    freezeResult,
    modalContent,
  }
}
