<script setup lang="ts">
import View from '~/pages-components/c2c/index.vue'
import { useIsImtoken } from '~/composables/imtoken/useIsImtoken'

const i18nHead = useLocaleHead({ addSeoAttributes: true })
useHead({
  title: () => `${$t('FHQk9K4iqotg9Cyxuov-b')} - ${$t('lPhxIshdtGozoJKIN65YA')}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
	  {
	  	property: 'og:image',
		  content: () => $t('8o1Tmd0w8LvIar9-6lm-Y'),
	  },
  ],
})

useIsImtoken()
</script>

<template>
  <ClientOnly>
    <View />
    <template #fallback>
      <div class="flex-center h400px">
        <i class="i-svg-spinners:180-ring-with-bg block text-text-blue text-30px" />
      </div>
    </template>
  </ClientOnly>
</template>
