import {
  createInjectionState,
  reactiveComputed,
  useTimeoutPoll,
} from '@vueuse/core'
import { actions } from 'xstate'
import { useMachine } from '@xstate/vue'
import { snakeCase } from 'lodash'
import { useAppBreakpoints } from '@peng_kai/theme/vue/index'
import { machine as c2cBuylMachine } from './c2cBuyMachine'
import { tron } from '~/utils/tron-v2'
import { extractContentInBrackets, jumpCompatible } from '~/utils'
import { formatTronErrorMessage } from '~/utils/tron-v2/tronError'

export const [useC2cBuyMachineProvide, useC2cBuyMachine] = createInjectionState(
  () => {
    const queryTronlinkOrder = ref()
    const queryTtpayOrder = ref()
    const rentalInformation = ref()
    const bp = useAppBreakpoints()
    const { account } = tronComp.useAccountCurrent()

    const machine = useMachine(c2cBuylMachine, {
      actions: {
        // todo tron的提示和api请求的提示不应该放一起的，这里不走tronError 错误处理，后期需要统一
        requestError: (ctx, ev) => {
          if (ev.data instanceof Error) {
            if (ev.data.message.includes('balance is not sufficient') || ev.data.message.includes('Tapos check error') || ev.data.message.includes('Account resource insufficient error'))
              aMessage.error($t('tm41OUsNwS4B7IkB1jey'))
            else if (ev.data.message.includes('Confirmation declined by user') || ev.data.message.includes('cancel') || ev.data.message.includes('cancle') || ev.data.message.includes('取消') || ev.data.message.includes('拒绝'))
              aMessage.error($t('Hk5eWgjl88eEeU0VPuaRz'))
            else if (ev.data.message.includes('Validate TransferContract error, no OwnerAccount'))
              aMessage.error($t('fiNURjHwnMnPYh48lYi'))
            else if (ev.data.message.includes('does not exist'))
              aMessage.error(`${extractContentInBrackets(ev.data.message)}  ${$t('AUdgXSOOMymyhIWK9Mg1A')}`)
            else
              aMessage.error(ev.data.message)
          }
        },

        toPayment: (ctx, ev) => {
          const { data } = ev as any
          if (ev.type === 'KEEP') {
            if (bp.ltLaptop)
              location.href = ctx.paymentUrl
            else
              window.open(ctx.paymentUrl, '_blank')
          }
          else if (ev.type === 'done.invoke.createTtpayOrder') {
            jumpCompatible($t('rVh4iCmGnClYuxNyVNn6', { url: data.payment_url, href: encodeURIComponent(window.location.href) }))
          }
        },

        setPayment: actions.assign((ctx, ev) => {
          const link
            = `${ev.data?.payment_url
          }&return_url=${
            encodeURIComponent(window.location.href)}`
          return {
            paymentUrl: link,
          }
        }),

        setInfo: actions.assign((ctx, ev) => {
          if (ev.type === 'BUY') {
            rentalInformation.value = ev
            return {
              formData: ev.formData,
              paymentAddress: ev.paymentAddress,
              discountInfo: ev.discountInfo,
              toAddress: ev.toAddress,
            }
          }

          if (ev.type === 'PAY') {
            return {
              toAddress: ev.toAddress,
            }
          }

          return {}
        }),
      },
      services: {
        /** dapp支付流程  开始 */
        precreateTronlinkOrder: async (ctx, ev) => {
          try {
            const params = {
              amount: toDecimalPlaces(ctx.discountInfo.amount, 6),
              toAddress: ctx.paymentAddress,
              fromAddress: ctx.toAddress,
            }
            const res = await tron.payTRXServerBroadcast(params)

            if (res)
              return res
          }
          catch (e) {
            // 兼容imtoken取消时不一样的返回信息
            const msg = formatTronErrorMessage(e)
            throw new Error(msg)
          }
        },
        createTronlinkOrder: async (ctx, ev) => {
          try {
            const data:
            | Api.GetParam<typeof apis.orderCreateC2C>['requestBody']
            | { [key: string]: string | number } = {}

            for (const key in ctx.formData)
              data[snakeCase(key)] = ctx.formData[key]

            data.signed_txn = ev.data
            data.resource_value = Number(data.resource_value)

            return await apis.orderCreateC2C({
              requestBody: data,
            }, { errorMessageMode: 'none' })
          }
          catch (e) {
            throw new Error(e.msg)
          }
        },
        queryTronlinkOrder: (ctx, ev) => async (callback) => {
          let second = 0
          while (second <= 15) {
            try {
              await sleep(2000)
              second++
              const res = await apis.orderQuery(
                {
                  order_no: ev.data.order_no,
                  trx_address: ev.data.pay_address,
                },
                { errorMessageMode: 'none' },
              )
              if (res) {
                queryTronlinkOrder.value = {
                  code: res.status,
                  status: 'success',
                }
                callback({ type: 'END' })
                break
              }
            }
            catch (e) {
              if (e.code !== 17019) {
                // 其他异常
                queryTronlinkOrder.value = {
                  code: e.code,
                  status: 'fail',
                }
                callback({ type: 'END' })
                throw new Error('')
              }
            }
          }
        },
        /** dapp支付流程  结束 */

        /** ttpay支付流程 开始 */
        createTtpayOrder: async (ctx, ev) => {
          const data:
          | Api.GetParam<typeof apis.ttpayOrderC2C>['requestBody']
          | { [key: string]: string | number } = {}

          data.pay_amount = toDecimalPlaces(ctx.discountInfo.amount, 6)

          for (const key in ctx.formData)
            data[snakeCase(key)] = ctx.formData[key]

          return await apis.ttpayOrderC2C({ requestBody: data }, { errorMessageMode: 'none' })
        },
        queryTtpayOrder: (ctx, ev) => (callback) => {
          const { out_trade_no } = ev.data as any
          const startTime = Date.now()
          const limtTime = 10 * 60 * 1000

          const { resume, pause } = useTimeoutPoll(async () => {
            if (Date.now() - startTime > limtTime) {
              pause()
              queryTtpayOrder.value = {
                code: 0,
                status: 'fail',
              }
              callback({ type: 'END' })
              return
            }

            // 接口请求和结果处理
            const res = await apis.orderQuery(
              { order_no: out_trade_no },
              {
                errorMessageMode: 'none',
                resultType: 'api',
                checkCode: false,
              },
            )
            if (res.code === 0) {
              queryTtpayOrder.value = {
                code: res.data?.status,
                status: 'success',
              }
              callback({ type: 'END' })
            }

            if (res.code !== 17019 && res.code !== 0) {
              queryTtpayOrder.value = {
                code: res.code,
                status: 'fail',
              }
              callback({ type: 'END' })
            }
          }, 2000)

          resume()
          return () => pause()
        },
        /** ttpay支付流程 结束 */

        /** 余额支付流程 开始 */
        createBalanceOrder: async (ctx) => {
          // 请求接口
          try {
            const data:
            | Api.GetParam<typeof apis.apiOrderCreateOrderByBalance>['requestBody']
            | { [key: string]: string | number } = {}
            for (const key in ctx.formData)
              data[snakeCase(key)] = ctx.formData[key]

            return await apis.apiOrderCreateOrderByBalance({ requestBody: { ...data, order_type: 2, pay_amount: toDecimalPlaces(ctx.discountInfo.amount, 6) } })
          }
          catch (e) {
            throw new Error(e)
          }
        },
        /** 余额支付流程 结束 */

      },
      guards: {
        isTronlink: (ctx, ev) => {
          return ev.paymentMethod === 'dapp'
        },
        isTtpay: (context, ev) => {
          return ev.paymentMethod === 'ttpay'
        },
        isBalance: (context, ev) => {
          console.log('guards', ev.paymentMethod === 'balance')
          return ev.paymentMethod === 'balance'
        },
        isPc: (context, event) => {
          return bp.laptopl
        },
      },
      delays: {},
    })

    const states = reactiveComputed(() => {
      const s = machine.state.value

      const isConfirm = s.matches('confirm')
      const isInfoConfirm = s.matches('info-confirm')
      const isIdle = s.matches('idle')
      const isTronlinkOrderResults = s.matches('tronlink.order-results')
      const isTtpayOrderResults = s.matches('TTpay.order-results')
      const isBalanceResults = s.matches('Balance.order-results')
      const isTtpayQueryOrder = s.matches('TTpay.ttpay-query-order')
      const isRequest = s.hasTag('request')
      const isQueryingTtpay = s.hasTag('querying-ttpay')
      const isRefresh = s.event.type === 'OK'
      const requesting = s.hasTag('tronlink-request') || s.hasTag('ttpay-request') || s.hasTag('balance-request')

      return {
        isConfirm,
        isInfoConfirm,
        isTtpayQueryOrder,
        isIdle,
        isTronlinkOrderResults,
        isTtpayOrderResults,
        isRequest,
        isQueryingTtpay,
        isRefresh,
        isBalanceResults,
        requesting,
      }
    })

    return {
      machine,
      states,
      queryTronlinkOrder,
      rentalInformation,
      queryTtpayOrder,
    }
  },
)
