<script setup lang="ts">
const props = defineProps<{
  max: number
  min: number
  initiaValue: number
}>()

const emits = defineEmits(['update'])

const max = ref(0)
const min = ref(0)
const isOpera = useBoolean(true)

const cursorState = ref()

const { count, inc, dec } = useNumber()

watchEffect(() => {
  count.value = props.initiaValue
  max.value = props.max
  min.value = props.min
})

function useNumber() {
  const count = ref(props.initiaValue)

  const inc = () => {
    if (count.value >= max.value) {
      count.value = max.value
      cursorState.value = 'max'
      return
    }
    cursorState.value = ''
    count.value++
    emits('update:initiaValue', count)
  }

  const dec = () => {
    if (count.value <= min.value) {
      count.value = min.value
      cursorState.value = 'min'
      return
    }
    cursorState.value = ''
    count.value--
    emits('update:initiaValue', count)
  }

  return {
    count,
    inc,
    dec,
  }
}
</script>

<template>
  <div class="custom-input-number">
    <i class="icon i-local:minus-icon text-2xl mx-1" :class="cursorState === 'min' ? 'cursor-not-allowed' : 'cursor-pointer'" @click="dec()" data-test-id="nwlz"/>
    <AConfigProvider :theme="{ components: { InputNumber: { borderRadius: 0, borderRadiusSM: 0, colorBorder: '#13192D' } } }">
      <AInputNumber
        v-model:value="count"
        v-bind="inputMoney"
        :controls="false"
        autocomplete="off"
        :step="1"
        class="z-input-number"
        :max="max"
        :min="min"
        :precision="0"
        @blur="emits('update:initiaValue', count)"
        @change="emits('update:initiaValue', count)"
      />
    </AConfigProvider>
    <i class="icon i-local:plus-icon text-2xl mx-1" :class="cursorState === 'max' ? 'cursor-not-allowed text-[#1A254A]' : 'cursor-pointer'" @click="inc()" data-test-id="f1e0"  />
  </div>
</template>

<style scoped lang="scss">
.custom-input-number{
  --uno: 'flex-between bg-bg rounded-xl px-1';

  .input{
    --uno: 'flex-1 mx-1';
  }

  :deep(.ant-input-number).z-input-number {
    width: 100%;

    .ant-input-number-input-wrap{
      input{
        --uno: 'text-center';
      }
    }
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }

  /* 火狐 */
  input{
    appearance: textfield;
  }
}
</style>
