import type { IProps } from '../SellPopup.vue'
import { EFreezeType } from '../../freeze/FreezePopup.vue'
import { tron } from '~/utils/tron-v2'
import { useMultiSignProvider } from '~/pages-components/c2c/hooks/useMultiSign'
import { useC2cSellMachine } from '~/pages-components/c2c/components/sell/machine/useC2cSellMachine'
// import { SentryUtils } from '~/utils/sentry'
import { discountResult } from '~/composables/app/getC2cAmountCalculator'

export function useSell(props: IProps) {
  // const sellScope = new SentryUtils('c2c')
  const { account, refetch } = tronComp.useAccountCurrent()

  const multiAddress = ref(props.muitiSingAddress)
  const isMultiSign = useBoolean(false)
  const mode = ref<EFreezeType>(EFreezeType.Short)
  const visibleFreeze = useBoolean(false)
  const spinning = useBoolean(false)
  const { orderDetail } = props
  const { config } = useAppState()
  const formData = reactive({
    settleAddress: account?.address,
    sellAmount: orderDetail.min_amount,
  })

  const {
    onValidatePower,
    info: multiSignIfo,
    setAddress,
  } = useMultiSignProvider()
  const { machine, states } = useC2cSellMachine()!

  const sellForm = useAntFormValidator({
    payAddress: {
      value: multiAddress,
      validator(rule, value) {
        if (isMultiSign.is && !tron.isAddress(value))
          return Promise.reject($t('sHmcz_3thcjWOabRqDhT'))

        return Promise.resolve()
      },
    },
    settleAddress: {
      value: toRef(formData, 'settleAddress'),
      validator(rule, value) {
        if (!tron.isAddress(value))
          return Promise.reject($t('e8ik9k7EhmgdQfl0ZOlu'))

        return Promise.resolve()
      },
    },
    sellAmount: {
      value: toRef(formData, 'sellAmount'),
      validator(rule, value) {
        if (!value)
          return Promise.reject($t('n305EyjKQuab3Q0Ym5Msu'))

        for (const [s, e] of toValue(ranges)) {
          if (value < s && value > e) {
            return Promise.reject(
              $t('hB-Pdl_5hJSlPkso_yNMs') + formatAmount(energyMin.value, 0),
            )
          }
        }
        return Promise.resolve()
      },
    },
  })
  const onSubmit = async () => {
    try {
      const formState = (await sellForm.$form.value
        ?.validate()
        .catch(() => {})) as typeof sellForm.formState

      if (formState) {
        setAddress(toValue(multiAddress))

        onValidatePower(isMultiSign.is, 57)
        await validator()
        console.log('formState.sellAmount', formState.sellAmount)
        machine.send({
          type: 'SUBMIT',
          orderDetail,
          ownerAddress: getOwnerAddress(),
          resourceValue: formState.sellAmount,
          settleAddress: formState.settleAddress,
          frozenBalance: sellInfo.value.freezeTRX,
          skipDuplicateCheck: 0,
          multiSignIfo,
          isMultiSign: isMultiSign.is,
          resourceType: toValue(resourceType),
        })
      }
    }
    catch (e) {
      if (typeof e === 'object') {
        const msg = formatTronErrorMessage(e)
        aMessage.error(msg)
        return
      }

      if (e instanceof Error) {
        // sellScope.setTagPath('c2c', ['sell'])
        // sellScope.captureException(e)
        aMessage.error(e.message)
      }
    }
  }

  const getOwnerAddress = (): string => {
    return isMultiSign.is ? multiAddress.value : account.address
  }

  const validator = () => {
    return new Promise((resolve, reject) => {
      if (orderDetail.receive_address == getOwnerAddress()) {
        aMessage.error($t('DogFR2AdsY9AJ9A8dDB8y'))
        reject()
      }
      if (!sellInfo.value.freezeTRX && !formData.sellAmount) {
        aMessage.error($t('pVxWPz1WarabRXO2VSUyt'))
        reject()
      }
      resolve('')
    })
  }

  // 打开质押弹窗
  const openFreezeModal = (e: EFreezeType) => {
    mode.value = e
    setAddress(toValue(multiAddress))
    visibleFreeze.setTrue()
  }
  const closeFreeze = () => visibleFreeze.setFalse()

  const freezeModalDetail = computed<IProps['orderDetail']>(() => {
    const info = {
      orderDetail,
      sellAmount: formData.sellAmount ?? orderDetail.min_amount,
      mode: EFreezeType.Short,
      multiAddress: isMultiSign.is ? multiAddress.value : '',
    }
    info.mode = mode.value

    return info
  })

  const resourceType = computed(() =>
    orderDetail.resource_type === 1 ? 'ENERGY' : 'BANDWIDTH',
  )

  const energyMin = computed(() =>
    Math.min(
      config.value?.order.energy_min_value ?? 10000,
      config.value?.order.energy_c2c_min_value ?? 10000,
    ),
  )

  const ranges = computed(() => {
    return [
      [0, orderDetail.min_amount - toValue(energyMin)],
      [orderDetail.max_amount ?? 10000, orderDetail.max_amount ?? 10000],
    ]
  })

  const sellInfo = computed(() => {
    const { resource } = account
    const info = {
      freezeTRX: 0,
      amount: 0,
    }
    if (!resource)
      return info
    // 计算收入
    const params = {
      rentTimeUnit: orderDetail.rent_time_unit,
      rentDuration: orderDetail.rent_duration,
      resourceValue: formData.sellAmount,
      resourceType: orderDetail.resource_type,
      priceInSun: orderDetail.price_in_sun,

    }
    const { amount } = discountResult(params)
    info.amount = amount * 0.75

    info.freezeTRX = Math.ceil(
      formData.sellAmount / (resource.energyUnitPrice ?? 0),
    )
    if (orderDetail?.resource_type === 0) {
      info.freezeTRX = Math.ceil(
        formData.sellAmount / (resource.netUnitPrice ?? 0),
      ) // 按全网质押单价计算
    }
    return info
  })

  const showSellRang = computed(() => {
    return (
      (orderDetail.resource_split_value ?? 0) < (orderDetail.max_amount ?? 0)
      && orderDetail.max_amount > orderDetail.min_amount
    )
  })

  return {
    onSubmit,
    openFreezeModal,
    freezeModalDetail,
    resourceType,
    formData,
    ranges,
    energyMin,
    sellInfo,
    closeFreeze,
    multiAddress,
    accountAddress: account.address,
    visibleFreeze,
    isMultiSign,
    spinning,
    sellForm,
    showSellRang,
  }
}
