<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { tron } from '~/utils/tron-v2'
import { PromptModel } from '~/components/modal'

const props = defineProps(['close'])
const visible = useBoolean()
const prompt = ref(false)
const { account } = tronComp.useAccountCurrent()
const { voteList, isLoading, votetInfo } = useVoteData()

function useVoteData() {
  const querier = useQuery({
    queryKey: [apis.witnessApi.id],
    staleTime: 0,
    queryFn: () =>
      apis.witnessApi(
        { trx_address: account.address },
        { errorMessageMode: 'none' },
      ),
  })

  // 因为计算属性只读所以用ref
  const voteList = ref<Api.GetData<typeof apis.witnessApi>>()
  // 投票数据信息
  const votetInfo = computed(() => {
    const _tronVoteData = account.resource
    const info = {
      voteTotal: 0,
      voteUsed: 0,
      votableNumber: 0, // 可投票数
      maxVoteNumber: 0, // 账号可投总票数
      voteUsedStic: 0,
    }

    if (!_tronVoteData)
      return info

    info.voteTotal = _tronVoteData.tronPowerLimit ?? info.voteTotal
    info.voteUsedStic = _tronVoteData.tronPowerUsed ?? info.voteUsedStic
    info.voteUsed = 0
    info.votableNumber = info.voteTotal - info.voteUsedStic
    info.maxVoteNumber = info.voteTotal - info.voteUsed

    return info
  })

  watchEffect(() => {
    if (querier.data.value)
      voteList.value = querier.data.value?.map(i => ({ ...i, count: null }))
  })

  return {
    voteList,
    isLoading: querier.isLoading,
    votetInfo,
  }
}

const { onInputBlur, onSetALL, onSubmit, submiting } = useVote(voteList)

function useVote(voteList) {
  const { votetInfo } = useVoteData()
  const inputTotal = ref(0)
  const formData = ref()
  const submiting = ref(false)
  const onInputBlur = (key: number) => {
    if (voteList.value[key].count % 1 !== 0) {
      voteList.value[key].count = 0
      return
    }

    if (voteList.value[key].count && voteList.value[key].count > votetInfo.value.maxVoteNumber) {
      voteList.value[key].count = 0
      return
    }

    // 设置总输入数量
    const filterDataRes = getInputTotal()

    if (!voteList.value[key].count) {
      formData.value = Object.fromEntries(filterDataRes)
      return
    }

    if (filterDataRes?.length === 1) {
      // 可用余额 & 输入金额  中  取其一 min 给表单
      voteList.value![key].count = Math.min(
        filterDataRes[0][1] as number,
        votetInfo.value.maxVoteNumber,
      )
      formData.value = Object.fromEntries(filterData()!)
      return
    }

    if (inputTotal.value > votetInfo.value.maxVoteNumber) {
      const count = votetInfo.value.maxVoteNumber
        - (inputTotal.value - voteList.value[key].count)
      voteList.value[key].count
        = count < 0 ? 0 : count
    }
    formData.value = Object.fromEntries(filterData()!)
  }

  const onSetALL = (key: number, i: any) => {
    getInputTotal()
    console.log('inputTotal.value', inputTotal.value)
    const usableValue = votetInfo.value.maxVoteNumber - inputTotal.value
    console.log('usableValue', usableValue)
    if (usableValue <= 0 || i.count === votetInfo.value.maxVoteNumber) {
      aMessage.error($t('oXusL8QgHgCcpMgGuff4'))
      return
    }

    getInputTotal()
    voteList.value[key].count = usableValue + voteList.value[key].count
    getInputTotal()
    formData.value = Object.fromEntries(filterData()!)
  }

  const onSubmit = async () => {
    if (!formData.value || JSON.stringify(formData.value) === '{}') {
      aMessage.error($t('atiYfTjcYb7TymJmo22w'))
      return
    }

    submiting.value = true
    try {
      await tron.vote(account.address, formData.value)
      prompt.value = true
    }
    catch (e) {
      if (typeof e === 'string') {
        if (e.includes('balance is not sufficient'))
          aMessage.error($t('smItyVQaod8JL2iUWmxQR'))
        else if (e.includes('Confirmation declined by user'))
          aMessage.error($t('Hk5eWgjl88eEeU0VPuaRz'))
        else
          aMessage.error(e)
      }
      if (e instanceof Error)
        aMessage.error(e.message)

      submiting.value = false
      props.close()
    }
  }
  function filterData() {
    return voteList.value
      ?.filter(item => item.hasOwnProperty('count') && item.count)
      .map((item) => {
        if (item.count <= votetInfo.value.maxVoteNumber)
          return [item.address, Number(item.count)]
      })
  }

  function getInputTotal() {
    // 所有的输入都会加入这个数组
    const filterDataRes = filterData()

    // 如果是空数组，不进行累加
    if (filterDataRes?.length === 0) {
      inputTotal.value = 0
      return []
    }

    inputTotal.value = filterDataRes
      ?.map(item => Number(item[1]))
      ?.reduce(
        (previousValue, currentValut) => previousValue + currentValut,
      ) as number
    return filterDataRes
  }

  return {
    onInputBlur,
    onSetALL,
    onSubmit,
    submiting,
  }
}

watchEffect(() => {
  if (!visible.is) {
    voteList.value?.forEach((i) => {
      i.count = null
    })
  }
})

defineExpose({
  open: visible.setTrue,
  close: visible.setFalse,
})
</script>

<template>
  <ASpin :spinning="submiting">
    <div class="bg-[#283460] p-[16px] mt-[22px] rounded-[10px] text-[#e2e8f2]">
      <div>{{ $t("td2xAwzG82vVhJb97QprO") }} {{ votetInfo.votableNumber }}</div>
      <div>{{ $t("LEJRskmB0II352tZ4XAJg") }} {{ votetInfo.voteTotal }}</div>
    </div>
    <div class="mt-[10px] mb-[10px] text-[14px]">
      {{ $t('lRrl68CbJzCdtCfd0Nh2h', {
        count: votetInfo.voteTotal,
      }) }}
    </div>

    <ASpin :spinning="isLoading">
      <div class="max-h-[460px] overflow-y-auto bg-[#283460] py-8px px-16px rounded-[10px]">
        <div v-for="(item, i) in voteList" :key="i" class="grid grid-cols-[auto_auto] gap-2 py-3 [border-bottom:1.5px_dashed_#333c67] lt-tablet:text-14px">
          <ATooltip :title="item.name">
            <div class="break-all line-clamp-1">{{ i + 1 }}、{{ item.name }}</div>
          </ATooltip>

          <div class="justify-self-end text-#e2e8f2">
            {{ formatAmount(item.real_time_votes, 3) }} {{ $t('WRIULHtQPGGPd6apO_M1W') }}
          </div>

          <div class="">{{ $t("FHHt6afIqSCW2kni5e8-b") }}: {{ item.annualizedRate }}%</div>

          <div class="flex items-center justify-self-end">
            <AInputNumber
              v-bind="inputMoney"
              v-model:value="item.count"
              class="vote-input"
              :controls="false"
              :min="0"
              @blur="onInputBlur(i)"
            />
            <span class="text-[#148af5] ml-2 cursor-pointer" data-test-id="3xfs" @click="onSetALL(i, item)">
              {{ $t("OFmdpW6mVpnIIY2n8ZgV5") }}
            </span>
          </div>
        </div>
      </div>

      <div class="flex justify-center items-center mt4 gap4">
        <AButton class="cancel-btn flex-1" data-test-id="11mb" @click="props.close()">{{ $t('hAns2WaHjEPbTgBxlqXn') }}</AButton>
        <AButton class="comfirm-btn flex-1" data-test-id="z2xm" @click="onSubmit()">{{ $t('tyv0SatMpCJkxg6oLqddv') }}</AButton>
      </div>
    </ASpin>
  </ASpin>
  <PromptModel v-model:open="prompt" :content="$t('Nh1-IZOaTQskN_j3cMRLE')" @close="props.close()" />
</template>

<style scoped lang="scss">
.vote-input {
  width: 100px;

  :deep(input) {
    height: 34px !important;
  }
}

// 滚动条整体样式
// 高宽分别对应横竖滚动条的尺寸
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

// 滚动条里面小方块
::-webkit-scrollbar-thumb {
  background: rgb(38 110 241 / 100%);
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgb(38 110 241 / 100%);
}

// 滚动条里面轨道
::-webkit-scrollbar-track {
  background: rgb(53 69 124 / 100%);
  border-radius: 0;
  box-shadow: inset 0 0 5px rgb(53 69 124 / 100%);
}
</style>
