<script lang="ts" setup>
import SelfHelpOrder from '../sell/SelfHelpOrder.vue'
import RightExtra from './RightExtra.vue'

const resourceType = ref(0)
</script>

<template>
  <div class="current-order-wrapper">
    <div class="header">
      <AConfigProvider :theme="{ components: { Select: { controlHeight: $bp.ltTablet ? 32 : 40 } } }">
        <ASelect
          v-model:value="resourceType"
          class="res-type-select"
          popupClassName="ant-cover__basic-select-dropdown"
          :bordered="false"
          data-test-id="BFFL"
        >
          <ASelectOption :value="0">{{ $t('OFmdpW6mVpnIIY2n8ZgV5') }}</ASelectOption>
          <ASelectOption :value="1">{{ $t('YUG0RoDj9Kcw8PtVRPR9Y') }}</ASelectOption>
          <ASelectOption :value="2">{{ $t('IgN1EoVRFTDyCVLbFG-oT') }}</ASelectOption>
        </ASelect>
      </AConfigProvider>

      <RightExtra class="right" />
    </div>

    <SelfHelpOrder class="main" :resourceType="resourceType" />
  </div>
</template>

<style lang="scss" scoped>
.current-order-wrapper {
  --padding: 30px;
  --y-padding: calc(var(--padding) - 8px);

  padding: var(--y-padding) var(--padding);
  background: var(--app-gradient-top-light-d);
  border-radius: var(--padding);

  @media bp-lt-tablet {
    --padding: 16px;
    --y-padding: 16px;
  }

  > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--y-padding)
  }

  > .main {
    margin: 0 calc(-1 * var(--padding));
  }
}

.res-type-select {
  min-width: 100px;

  :deep(.ant-select-selection-search) {
    height: 40px !important;
  }

  :deep(.ant-select-selector){
    padding: 0 !important;
    margin-right: 16px;
    background: transparent;
    border: none !important;

    .ant-select-selection-item {
      font-size: 22px;
      font-weight: 500;

      @media bp-lt-tablet {
        font-size: 16px;
      }
    }
  }

  :deep(.ant-select-arrow){
    color: #8098bf;
  }
}
</style>
