<script setup lang="ts">
import { reactiveComputed } from '@vueuse/core/index'
import { useC2cBuyMachine } from './machine/useC2cBuyMachine'
import { useBuy } from './hooks/useBuy'
import { TronRes } from '~/utils/tron-v2'
import { EPaymentMethod } from '~/components/pay-type/src/PayType.vue'
import { useUserInfo } from '~/composables/app'
import { jumpCompatible,timeSecondFilters } from '~/utils'

const { machine, states, rentalInformation: info } = useC2cBuyMachine()!
const { account, isConnected } = tronComp.useAccountCurrent()
const {
  payType,
  togglePayType,
  paymentMethod,
  discount,
  isOpenTTPay,
  isUnaddressDom,
} = useBuy()!
const { trxMoney } = useUserInfo()
const buttonGroup = reactiveComputed(() => {
  const amount = toDecimalPlaces(info.value?.discountInfo?.amount, 6)
  return {
    walletConnection:
      !toValue(isOpenTTPay) && toValue(isUnaddressDom) && !toValue(isConnected),
    walletConnectionToPay:
      toValue(payType) === EPaymentMethod.DAPP
      && toValue(isUnaddressDom)
      && !toValue(isConnected),
    download:
      toValue(payType) === EPaymentMethod.DAPP && !toValue(isUnaddressDom),
    pay:
      toValue(payType) === EPaymentMethod.TTPAY
      || (toValue(payType) === EPaymentMethod.BALANCE
        && (unref(trxMoney) ?? 0) >= amount)
      || (toValue(payType) === EPaymentMethod.DAPP && toValue(isConnected)),
    balanceTip:
      toValue(payType) === EPaymentMethod.BALANCE
      && (unref(trxMoney) ?? 0) < amount,
  }
})

// function jumpRule() {
//   jumpCompatible(`/console/buyer/recharge?lang=${$t('console-lang')}`)
// }
</script>

<template>
  <div class="pay-confirm-container">
    <div class="popup">
      <h2 class="title font-bold text-[#e2e8f2]">
        {{ $t("9wH_EgVSZ442WoIiYLM55") }}
      </h2>
      <div v-if="account.address" class="pay-address tags">
        <span class="left">{{ $t("K3YbjRNYwB37wyxkpldjL") }}</span>
        <span class="right">
          {{
            account.address
              ? $bp.tabletl
                ? account.address
                : desensitization(account.address, 10, -10)
              : "-"
          }}
        </span>
      </div>
      <div class="receiver-address tags">
        <span class="left">{{ $t("jiir2eTmqaOvbAf1uGj06") }}</span>
        <span class="right">
          {{
            info.formData.receiveAddress
              ? $bp.tabletl
                ? info?.formData.receiveAddress
                : desensitization(info?.formData.receiveAddress, 10, -10)
              : "-"
          }}
        </span>
      </div>

      <div class="rent-resource tags">
        <span class="left">{{ $t("lHkgHqpcM-kz9yVuP2ATM") }}</span>
        <span class="right">
          <span class="text-[#27c1a5]">{{ info?.formData.resourceValue }}
          </span>
          {{
            info.formData.resourceType === TronRes.ENERGY
              ? $t("YUG0RoDj9Kcw8PtVRPR9Y")
              : $t("IgN1EoVRFTDyCVLbFG-oT")
          }}
        </span>
      </div>

      <div class="notice">{{ $t("CWgS3UyhhWcgIMAHl76Wv") }}</div>

      <div class="rent-day tags">
        <span class="left">{{ $t("c1R3GTGbhHGUQ-MFaNQpC") }}</span>
        <span class="right">
          <span class="text-[#27c1a5]">
            {{ info?.formData.rentDuration ?? 0 }}
          </span>
           {{ timeSecondFilters(info?.formData?.rentTimeSecond) }}</span>
      </div>

      <div class="pay-amount tags">
        <span class="left">{{ $t("eZ4sG6S0vn3U5mdh1etvj") }}</span>
        <span class="right"><span class="text-[#27c1a5]">{{
          formatAmount(info?.discountInfo?.amount, 6)
        }}</span>
          TRX
        </span>
      </div>
    </div>
    <!-- 支付方式 -->
    <div class="pay-type">
      <div class="mb-[15px] text-[16px] font-500">
        {{ $t("k9SO_yf92B4fzr1Eartca") }}
      </div>
      <div class="select-pay-type">
        <PayType :payType="payType" class="b2c" @change="togglePayType" />
      </div>
    </div>
    <!-- 支付按钮 -->
    <div class="footer">
      <!-- 确认支付 -->
      <AButton
        v-if="buttonGroup.pay"
        key="submit"
        class="pay-order comfirm-btn"
        block
        :loading="states.requesting"
        data-test-id="vcc0"
        @click="
          machine.send('PAY', {
            paymentMethod: unref(paymentMethod),
            toAddress: account.address,
            discountInfo: discount,
          })
        "
      >
        {{ $t("CMMAnKSH723nCLL3zAvPb") }}
      </AButton>

      <!-- 连接钱包  -->
      <AButton
        v-if="buttonGroup.walletConnectionToPay"
        class="comfirm-btn"
        block
        data-test-id="6f2d"
        @click="globalModal.ConnectWallet?.open()"
      >
        {{ $t("wuWqn9c4GPKjbVAkKcBVu") }}
      </AButton>

      <!-- 下载DAPP支付钱包 -->
      <AButton
        v-if="buttonGroup.download"
        class="comfirm-btn"
        block
        data-test-id="55a1"
        @click="globalModal.DappDownloadGuide?.open({})"
      >
        {{ $t("MdfePiCQIvaAcvS79HcdD") }}
      </AButton>
      <!-- 余额不足提示 -->
      <AButton
        v-if="buttonGroup.balanceTip"
        class="comfirm-btn"
        block
        @click="
          jumpCompatible(`/console/buyer/recharge?lang=${$t('console-lang')}`)
        "
      >
        {{ $t("necasD5ndOJvyEQtNhj11") }}
      </AButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notice {
  margin: -10px 0 10px 5px;
  font-size: 14px;
  color: var(--secondary-text);
}

h2 {
  margin: 5px 0 20px;
  font-size: 24px;
  text-align: center;
}

.rent-grid {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

// 支付方式
.tags {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 14px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #707582;
  background: var(--bg-one);
  border-radius: 6px;

  @media bp-lt-tabletl {
    height: 40px;
  }

  .left {
    --uno: 'lt-mobile:text-14px';

    color: var(--secondary-text);
  }

  .right {
    color: var(--first-text);
  }
}

.footer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
</style>
