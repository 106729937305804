<script lang="ts">
import type { ColumnsType } from 'ant-design-vue/es/table'
import { useQuery } from '@tanstack/vue-query'
import { reactiveComputed } from '@vueuse/core'
import { useAppBreakpoints } from '@peng_kai/theme/vue'
import Progress from './Progress.vue'
import ListEmpty from './ListEmpty.vue'
import { CencelOrder } from '@/components/cencel-order'
import MineOrderDetail from './MineOrderDetail.vue'
import { TronRes } from '~/utils/tron-v2'
import CheckBox from '~/components/check-box'
</script>

<script setup lang="ts">
function columns(): ColumnsType {
  return [
    {
      title: $t('MdLWMNeCJsOrXnrrrWLgX'),
      dataIndex: 'info',
      align: 'left',
    },
    {
      title: $t('jiir2eTmqaOvbAf1uGj06'),
      dataIndex: 'receiveAddress',
      align: 'left',
    },
    {
      title: $t('c1R3GTGbhHGUQ-MFaNQpC'),
      dataIndex: 'rent_duration',
      align: 'left',
    },
    {
      title: $t('uiB6J5wmqrVMS1jIdBREC'),
      dataIndex: 'createTime',
      align: 'left',
    },
    {
      title: $t('jekV1ePoZlW4qWmKlH4r'),
      dataIndex: 'order_type',
      align: 'center',
      responsive: ['lg'],
    },
    {
      title: $t('wl2jDaljc20gK9boATaeL'),
      dataIndex: 'progress',
      align: 'center',
      responsive: ['lg'],
    },
    {
      title: $t('Zoxdb3CKEIj7JrZH5gr63'),
      dataIndex: 'operation',
      align: 'center',
    },
  ]
}

function useBuyTable() {
  const { isConnected, account } = tronComp.useAccountCurrent()

  const pagination = reactiveComputed(() => {
    return {
      page: 1,
      pageSize: 10,
    }
  })

  const range = ref(0)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [apis.myPayOrder.id, pagination, () => account.address, range],
    enabled: isConnected,
    queryFn: () =>
      apis.myPayOrder({
        trx_address: account.address,
        page: pagination.page,
        page_size: pagination.pageSize,
        range: range.value,
      }),
  })

  const listData = computed(() => data.value?.list)
  const total = computed(() => data.value?.pagination.total)
  const loading = computed(() => isConnected.value && isLoading.value)

  return {
    listData,
    refetch,
    pagination,
    total,
    isLoading: loading,
    range,
  }
}

function getProgressVal(row: any) {
  const { frozen_resource_value, resource_value } = row
  if (!frozen_resource_value || !resource_value)
    return 0
  return (frozen_resource_value / resource_value) * 100
}

const { isConnected } = tronComp.useAccountCurrent()
const { listData, isLoading, pagination, total, refetch, range } = useBuyTable()

const CencelOrderRef = ref<InstanceType<typeof CencelOrder>>()
const MineOrderDetailRef = ref<InstanceType<typeof MineOrderDetail>>()
const bp = useAppBreakpoints()
const mode = computed(() => [{ value: 0, name: $t('faLnuJV2Cp2PhFw1nEhf') }, { value: 1, name: $t('q3iXbB_3ifgWp21Leqomw') }])
</script>

<template>
  <div class="buy-table">
    <ASelect
      v-model:value="range"
      class="sort-select"
      popupClassName="ant-cover__basic-select-dropdown"
      :bordered="false"
      data-test-id="BFF9"
    >
      <ASelectOption v-for="i in mode" :key="i.value" :value="i.value">{{ i.name }}</ASelectOption>
    </ASelect>
    <ATable
      v-if="$bp.tablet"
      class="ant-cover__basic-table define-both-sides-padding"
      :columns="columns()"
      :loading="isLoading"
      :data-source="listData"
      :pagination="false"
    >
      <template #emptyText>
        <ListEmpty :class="{ invisible: isLoading }" :isConnected="isConnected" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'info'">
          <div class="sun">
            {{ $t("d6ggOrqnIdtCyuMDDHxC6") }}{{ record.price_in_sun }} sun
          </div>
          <div class="resouce">
            {{
              record.resource_type === 1
                ? $t("Mqe_hlD_yGPsAMB9oaO1D")
                : $t("xo7kGD9R2fV50wOIihlhL")
            }}：{{ formatAmount(record.resource_value, 0) }}
          </div>
        </template>

        <template v-if="column.dataIndex === 'order_type'">
          <span>{{ orderTypeFilters(record.order_type) }}</span>
        </template>

        <template v-if="column.dataIndex === 'progress'">
          <Progress
            style="width: 100%"
            :height="6"
            :width="180"
            :status="record.status"
            :current="record.frozen_resource_value"
            :total="record.resource_value"
          />
        </template>

        <template v-if="column.dataIndex === 'receiveAddress'">
          <ATooltip placement="topLeft" overlayClassName="custom-tooltip">
            <template #title>
              <div>{{ record.receive_address }}</div>
            </template>
            {{ desensitization(record.receive_address, 8, -8) }}
          </ATooltip>

          <Progress
            class="progress-mb hidden lt-tablet:block"
            :height="5"
            :width="200"
            :status="record.status"
            :current="record.frozen_resource_value"
            :total="record.resource_value"
          />
        </template>
        <template v-if="column.dataIndex === 'rent_duration'">
          {{ timeFn(record.rent_duration, record.rent_time_unit) }}
        </template>

        <template v-if="column.dataIndex === 'createTime'">
          {{ $d(record.create_time * 1000, "full") }}
        </template>

        <template v-if="column.dataIndex === 'operation'">
          <div class="mine-order-operation m-[0_auto]">
            <div v-if="record.status === 4 || record.status === 15" class="flex justify-center items-center">
              <AButton type="primary" data-test-id="jjwv" @click="CencelOrderRef?.open(record)">
                {{ $t("3Sy5Ym3mSx7yphQbJCdPo") }}
              </AButton>

              <AButton v-if="record.status === 15" type="link" data-test-id="5vts" @click="MineOrderDetailRef?.open(record)">
                {{ $t("6uSlooIREkQF6k1hgnuHF") }}
              </AButton>
            </div>

            <AButton
              v-else-if="!record.is_split && (record.status === 6 || record.status === 9)"
              type="link"
              :href="`https://tronscan.org/#/transaction/${record.frozen_tx_id}`"
              target="_blank"
            >
              {{ $t("vIVz1OAClDxc6NJlYJkoO") }}
            </AButton>

            <AButton
              v-else-if="record.is_split && (record.status === 6 || record.status === 9) "
              type="link"
              data-test-id="gw5y"
              @click="MineOrderDetailRef?.open(record)"
            >
              {{ $t("6uSlooIREkQF6k1hgnuHF") }}
            </AButton>

            <span v-else> - </span>
          </div>
        </template>
      </template>
    </ATable>

    <div v-else class="mb-containar">
      <div v-if="listData?.length" class="mb-panel">
        <div
          v-for="(record, index) in listData"
          :key="index"
          class="buy-panel"
        >
          <div class="header">
            <div class="left">
              {{ $d(record?.create_time * 1000 ?? 0, "full") }}
            </div>
            <div class="right">
              <span v-if="record.status === 4 || record.status === 15">
                <span class="ml2 !lt-mobile:px-[5px] !lt-mobile:text-[14px]" data-test-id="kv62" @click="CencelOrderRef?.open(record)">{{ $t("3Sy5Ym3mSx7yphQbJCdPo") }}</span>
                <span
                  v-if="record.status === 15"
                  class="pay-detail-mb lt-mobile:text-14px"
                  data-test-id="llmn"
                  @click="MineOrderDetailRef?.open(record)"
                >
                  {{ $t("6uSlooIREkQF6k1hgnuHF") }}</span>
              </span>

              <span v-else-if="!record.is_split && (record.status === 6 || record.status === 9)">
                <a :href="`https://tronscan.org/#/transaction/${record.frozen_tx_id}`" target="_blank" data-test-id="bTOb">
                  {{ $t("vIVz1OAClDxc6NJlYJkoO") }}
                </a>
              </span>
              <span
                v-else-if=" record.is_split && (record.status === 6 || record.status === 9) "
                class="pay-detail-mb"
                data-test-id="02wp"
                @click="MineOrderDetailRef?.open(record)"
              >{{ $t("6uSlooIREkQF6k1hgnuHF") }}</span>
              <span v-else> - </span>
            </div>
          </div>
          <div class="body">
            <div class="flex justify-between mb-[5px]">
              <span>{{ $t("d6ggOrqnIdtCyuMDDHxC6") }}
                <span class="text-text-green">{{ record.price_in_sun }}</span>
                sun</span>
              <span v-if="record.status === 15">
                {{ `${$t("lWvHr2BgZj08wMykrDLER")} `
                }}{{ Math.floor(getProgressVal(record)) }}%</span>
              <span v-else>
                {{ statusFilters(record.status) ?? $t("N4TMtCt6FHODhwt16sRT6") }}
              </span>
            </div>
            <div class="flex justify-between mb-[5px]">
              <span>
                {{ record.resource_type === 1 ? $t("YUG0RoDj9Kcw8PtVRPR9Y") : $t("IgN1EoVRFTDyCVLbFG-oT") }}：<span class="text-text-green">{{ formatAmount(record.resource_value, 0) }}</span>
              </span>
              <span>
                {{ $t("p0TcyLOMXm3GmV5tWIlau") }}<span>
                  {{ timeFn(record.rent_duration, record.rent_time_unit) }}
                </span>
              </span>
            </div>
            <div class="flex justify-between">
              <span>{{ $t("OLwypolxNjoGJzKSZDdaC") }}{{ record.receive_address }}</span>
            </div>
          </div>
        </div>
      </div>
      <ListEmpty v-else :isConnected="isConnected" />
    </div>

    <div class="pagination-wrapper">
      <APagination
        v-model:current="pagination.page"
        v-model:page-size="pagination.pageSize"
        class="ant-cover__basic-pagination"
        :disabled="isLoading"
        :total="total"
        size="default"
        :hideOnSinglePage="true"
        :showSizeChanger="false"
      />
    </div>

    <CencelOrder ref="CencelOrderRef" :refresh="refetch" />
    <MineOrderDetail ref="MineOrderDetailRef" />
  </div>
</template>

<style scoped lang="scss">
:deep(.ant-table-cell){
  font-size: 16px;
}

.progress-mb {
  display: none;

  @media  bp-lt-tabletl {
    display: block;

    :deep(.progress-container) {
      margin: 0;
    }
  }
}

.mb-containar {
  .buy-panel {
    padding: 0 12px;
    background-color: #1a2344;

    .img {
      width: 25px;
      color: #2a47ab;
    }

    .header {
      --uno: 'text-primary';

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px 8px;
      margin: 0 -12px;
      background-color: #202c52;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        --uno: 'text-text-light text-14px';
      }
    }

  }

  .body {
    --uno: 'text-text py-20px text-14px';

    span {
      &:nth-child(2n) {
        text-align: right;
      }

      i {
        color: #707582;
      }

      em {
        font-style: normal;
        color: #707582;
      }
    }
  }
}

.sort-select {
  position: absolute;
  top: -60px;
  right: 0;
  width: 150px;

  @media bp-lt-tablet {
    top: -48px;
    right: -6px;
    width: 106px;
  }

  :deep(.ant-select-selector) {
    font-size: 16px;
    color: #8098bf;
    border: 2px solid #303c6a !important;
    border-radius: 10px !important;

    @media bp-lt-tablet {
      font-size: 12px;
    }
  }

  :deep(.ant-select-arrow) {
    color: #8098bf;
  }
}
</style>
