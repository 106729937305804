import { createInjectionState } from '@vueuse/core'

export const [useSellProvider, useSell] = createInjectionState((is: Ref<boolean>, address: Ref<string>) => {
  const isMultiSign = ref(is)
  const multiSingAddress = ref(address)
  const muktiSignResources = reactive({
    energy: 0,
    bandwidth: 0,
    balance: 0,
  })

  const { account: accountCurrent } = tronComp.useAccountCurrent()
  const { account: accountMultiSign } = tronComp.useAccount(
    multiSingAddress,
    toRef(accountCurrent, 'chainId'),
  )

  const setMultiSingAddress = (address: string) => {
    multiSingAddress.value = address
  }

  const remaineResource = computed(() => {
    const info = {
      ENERGY: 0,
      BANDWIDTH: 0,
    };

    // 只判断当前钱包有没有钱会导致，当前钱包没钱而多签钱包有钱而那不到
    const { delegable } = accountCurrent;
    const { delegable: delegableMulti } = accountMultiSign;

    if (!delegable && !delegableMulti)
      return info;

    const energyUnitPrice = toValue(isMultiSign) ? accountMultiSign.resource.energyUnitPrice : accountCurrent.resource.energyUnitPrice;
    const netUnitPrice = toValue(isMultiSign) ? accountMultiSign.resource.netUnitPrice : accountCurrent.resource.netUnitPrice;

    const canDelegatedBalanceForEnergy = toValue(isMultiSign)
      ? toRef(muktiSignResources, 'energy')
      : toRef(delegable, 'energy');

    info.ENERGY = Math.floor(
        (canDelegatedBalanceForEnergy.value ?? 0)
        * 10 ** -6,
      )
      * energyUnitPrice;

    const canDelegatedBalanceForBandwidth = toValue(isMultiSign)
      ? toRef(muktiSignResources.bandwidth)
      : toRef(delegable, 'bandwidth');

    info.BANDWIDTH = Math.floor(
        (canDelegatedBalanceForBandwidth.value ?? 0)
        * 10 ** -6,
      )
      * netUnitPrice;

    info.ENERGY = Math.floor(info.ENERGY ?? 0);
    info.BANDWIDTH = Math.floor(info.BANDWIDTH ?? 0);

    return info;
  });

  const balance = computed(() => {
    return toValue(isMultiSign) ? muktiSignResources.balance : accountCurrent.balanceTrx
  })

  watchEffect(() => {
    if (accountMultiSign) {
      muktiSignResources.energy = accountMultiSign?.delegable?.energy ?? 0
      muktiSignResources.bandwidth = accountMultiSign?.delegable?.bandwidth ?? 0
      muktiSignResources.balance = accountMultiSign.balanceTrx ?? 0
    }
  })

  return {
    setMultiSingAddress,
    remaineResource,
    balance,
  }
})
