<script setup lang="ts">
import { multiplyByTenToPower } from '@/utils'
import { useResource } from '@/composables/tron'
import { tron } from '~/utils/tron-v2'
import { PromptModel } from '~/components/modal'

const props = defineProps(['formData', 'close'])
const emits = defineEmits(['refetch'])
const { account: tronAccount, refetch } = tronComp.useAccountCurrent()
const loading = useBoolean(false)
const { resourceType: ResourceType } = useResource()
const data = useRecycleResoure()
//  类型未设置
const formData = ref({
  resource: 0,
})
const amount = ref(0)

onMounted(() => {
  formData.value = props.formData
  data.setAmount()
})
const prompt = ref(false)
const recoveryResult = useBoolean(true)
function cancel() {
  props.close()
}

function useRecycleResoure() {
  const maxTrx = computed(() => {
    const { lockBalance, balance } = formData.value ?? {}
    if (lockBalance === 0 && balance === 0)
      return 0
    const maxTrx = multiplyByTenToPower(balance - lockBalance, -6, 3)
    return maxTrx
  })

  const estimatedResource = computed(() => {
    const { resource } = tronAccount
    const { resource: resourceType } = formData.value ?? {}
    if (!resource)
      return {}
    const print
      = resourceType === 1 ? resource?.energyUnitPrice : resource?.netUnitPrice

    return {
      unitPrinceTrx: Math.floor(maxTrx.value * (print ?? 0)),
      customTrx: Math.floor(amount.value * (print ?? 0)),
    }
  })

  const setAmount = () => {
    if (!maxTrx.value)
      return
    amount.value = maxTrx.value
  }

  const onCconfirm = async () => {
    loading.setTrue()
    if (!amount.value)
      return aMessage.info($t('EKGuWqdKmtP20VvFNMN6i'))
    const options = formData.value?.resource === 1 ? { estimatedEnergy: data.estimatedResource?.customTrx } : { estimatedBandwidth: data.estimatedResource?.customTrx }
    try {
      const params = {
        amount: unref(amount) * 10 ** 6,
        receiverAddress: formData.value?.receiverAddress,
        resource: ResourceType[formData.value?.resource].type,
        address: formData.value?.ownerAddress,
        options,
      }
      const { result } = await tron.undelegateResource(params)

      prompt.value = true
      if (result) {
        refetch()
        emits('refetch')
        recoveryResult.setTrue()
      }
      else {
        recoveryResult.setFalse()
      }
    }
    catch (error) {
      if (error instanceof Error) {
        if (error instanceof String) {
          if (error.includes('balance is not sufficient'))
            aMessage.error($t('smItyVQaod8JL2iUWmxQR'))
          else if (error.includes('Confirmation declined by user'))
            aMessage.error($t('Hk5eWgjl88eEeU0VPuaRz'))
          else
            aMessage.error(error)
        }
      }
    }
    finally {
      loading.setFalse()
    }
  }

  return reactive({
    maxTrx,
    estimatedResource,
    setAmount,
    onCconfirm,
  })
}
</script>

<template>
  <ASpin :spinning="JSON.stringify() === '{}'">
    <AForm class="z-form antd-cover__bubble-help-form">
      <AFormItem class="z-form-item" :labelCol="{ span: 24 }">
        <template #label>
          <p class="pl-2px">{{ $t('k1cGpxC8S8imPgTAzC2ll') }}：</p>
          <a data-test-id="38ue" @click="data.setAmount">{{ $t('KOTT-xrZ1UMpWuu1NIpIi') }}：({{ formatAmount(data.maxTrx, 0) }})</a>
        </template>
        <AInputNumber
          v-bind="inputMoney"
          v-model:value="amount"
          class="ant-cover__input-number-hidden-addon-after w-100%"
          :controls="false"
          :max="toDecimalPlaces(data.maxTrx, 0)"
        >
          <template #addonAfter>
            <span class="text-primary">TRX</span>
          </template>
        </AInputNumber>
      </AFormItem>
    </AForm>
    <div class="mb-30px">
      <p class="flex-between ">
        <span class="flex-center">
          {{ $t('6OjWSw4ZW4tmjN2MTvts2') }}{{ $t(ResourceType[formData?.resource].label) }}
          <ATooltip class="z-tooltip">
            <template #title>
              {{ $t('P6c3Y4A-l4XC5s_ChuYx5') }}{{ $t(ResourceType[formData?.resource].label) }}
            </template>
            <span>
              <i class="icon" />
            </span>
          </ATooltip>
          ：
        </span>
        <span>
          {{ formatAmount(data?.estimatedResource?.customTrx, 0) }}</span>
      </p>
    </div>
    <div class="flex gap-20px">
      <AButton
        class="cancel-btn flex-1"
        :disabled="loading.is"
        data-test-id="e95t"
        @click="props.close"
      >
        {{ $t('S4xAz2Xg2jnP2zdK9zoXv') }}
      </AButton>
      <AButton
        class="comfirm-btn flex-1"
        :loading="loading.is"
        data-test-id="07xz"
        @click="data.onCconfirm"
      >
        {{ $t('UENBgObobQIqSj65k6uBN') }}
      </AButton>
    </div>

    <PromptModel v-model:open="prompt" :state="recoveryResult.is" :content="$t('Nh1-IZOaTQskN_j3cMRLE')" @close="cancel">
      {{ recoveryResult.is ? $t('aVTPxPnSn2jWQFzUwc4T-') : $t('usgrR37XTncLmstjYIMx5') }}
    </PromptModel>
  </ASpin>
</template>
