<script setup lang="ts"></script>

<template>
  <div class="order-create flex flex-col items-center">
    <i
      class="i-local:loding text-70px animate-spin mb-37px lt-mobile:text-35px"
    />
    <p class="text-24px text-text lt-mobile:text-16px">{{ $t('7pGTq2B-DWePfga8XN3S3') }}</p>
    <p class="text-md mt-13px mb-15px lt-mobile:text-12px">
      {{ $t('T9ztalcqOgHHfQ9rj093S') }}
    </p>
  </div>
</template>

