import { createInjectionState, reactiveComputed } from '@vueuse/core'
import { actions } from 'xstate'
import { useMachine } from '@xstate/vue'
import { machine as c2cSellMachine } from './c2cSellMachine'
import { TronRes, tron } from '~/utils/tron-v2'

export const [
  useC2cSellMachineProvide,
  useC2cSellMachine,
] = createInjectionState(() => {
  const machine = useMachine(c2cSellMachine, {
    actions: {
      init: actions.assign((ctx, ev) => {
        return {
          orderPreSubmitCode: 0,
        }
      }),
      resetting: actions.assign((ctx, ev) => {
        if (ev.type === 'OPEN') {
          return {
            orderPreSubmitCode: 0,
          }
        }
        return {}
      }),

      showErrorMessage: (context, event: any) => {
        const { data } = event
        if (event.type === 'error.platform.fetchOrderPredelegete') {
          if (data.code === 12008 || data.code === 12001 || data.code === 12006)
            return;
          else
            aMessage.error(data.msg);
        }
        if (event.type === 'error.platform.orderDelegateC2C') {
          if (data.code === 11000 && data.msg.includes('sig error')){
              aMessage.error($t('h3Ek4FbetwagbAzh95fRj'))
          }else
            aMessage.error(data.msg);
        }
      },

      preRequestError: actions.assign((context, event) => {
        return { orderPreSubmitCode: event?.data.code }
      }),

      orderQuerySuccess: actions.assign((ctx, ev) => {
        return {
          orderQueryRes: ev.data,
        }
      }),

      setInfo: actions.assign((ctx, ev) => {
        if (ev.type === 'SUBMIT') {
          let lockPeriod = 0
          if (ev.orderDetail.rent_time_unit === 'd')
            lockPeriod = (ev.orderDetail.rent_duration * 86400) / 3
          else if (ev.orderDetail.rent_time_unit === 'h')
            lockPeriod = (ev.orderDetail.rent_duration * 3600) / 3

          return {
            orderDetail: ev.orderDetail,
            ownerAddress: ev.ownerAddress,
            resourceValue: ev.resourceValue,
            settleAddress: ev.settleAddress,
            frozenBalance: ev.frozenBalance,
            skipDuplicateCheck: ev.skipDuplicateCheck,
            orderPreSubmitCode: 0,
            multiSignIfo: ev.multiSignIfo,
            isMultiSign: ev.isMultiSign,
            lockPeriod,
            resourceType: ev.resourceType,
          }
        }

        if (ev.type === 'DOUBLECHEC') {
          return {
            skipDuplicateCheck: ev.skipDuplicateCheck,
          }
        }

        return {}
      }),

    },
    services: {
      // 预下单
      fetchOrderPredelegete: async (ctx, ev) => {
        const res = await apis.orderPreDelegateC2C(
          {
            requestBody: {
              order_no: ctx.orderDetail.order_no,
              owner_address: ctx.ownerAddress,
              resource_type: ctx.orderDetail.resource_type,
              resource_value: ctx.resourceValue,
              frozen_balance: ctx.frozenBalance,
              permission_id: ctx.multiSignIfo.permissionId,
              skip_duplicate_check: ctx.skipDuplicateCheck,
            },
          },
          { errorMessageMode: 'none' },
        )
        return res
      },

      // 拉起签名
      fetchDelegateResourceSign: async (ctx, ev: any) => {
        const options = ctx.orderDetail.resource_type === TronRes.BANDWIDTH ? { estimatedBandwidth: String(ctx.resourceValue) } : { estimatedEnergy: String(ctx.resourceValue) }
        try {
          const sign = await tron.getDelegateResourceSign({
            c2CPreDelegateResp: { ...ev.data, __options: options },
            isMultiSign: toValue(ctx.isMultiSign),
            permissionId: ctx.multiSignIfo.permissionId,
            isOwnerPermission: ctx.multiSignIfo.isOwnerPermission,
          })
          sleep(100);
          return sign
        }
        catch (error) {
          if (error instanceof Error) {
            const msg = formatTronErrorMessage(error);
            aMessage.error(msg);
          }
          throw new Error('');
        }
      },

      fetchdelegateResourceServerBroadcast: async (ctx, ev) => {
        try {
          const params = {
            frozenBalance: ctx.frozenBalance * 10 ** 6,
            ownerAddress: ctx.ownerAddress,
            receiveAddress: ctx.orderDetail.receive_address,
            resourceType: ctx.resourceType,
            isMultiSign: ctx.isMultiSign,
            multiSignIfo: ctx.multiSignIfo,
            isLock: ctx.orderDetail.is_lock === 1,
            lockPeriod: ctx.orderDetail?.lock_period
              ? ctx.orderDetail?.lock_period
              : ctx.lockPeriod,
          }
          return await tron.delegateResourceServerBroadcast(params)
        }
        catch (error) {
          if (error instanceof Error) {
            const msg = formatTronErrorMessage(error);
            aMessage.error(msg);
          }
          throw new Error('');
        }
      },

      orderDelegateC2C: async (ctx, ev: any) => {
        return await apis.orderDelegateC2C({
          requestBody: {
            order_no: ctx.orderDetail.order_no,
            settle_address: ctx.settleAddress,
            resource_value: ctx.resourceValue,
            signed_txn: ev.data,
            resource_type: ctx.orderDetail.resource_type,
          },
        }, { errorMessageMode: 'none' })
      },

      orderQuery: async (ctx, ev) => {
        // 轮询订单
        let second = 0
        do {
          try {
            await sleep(2000)
            const res = await apis.orderQuery(
              {
                order_no: ctx.orderDetail.order_no,
              },
              { errorMessageMode: 'none' },
            )
            if (res)
              return res
            second++
          }
          catch (error: any) {
            if (ApiError.is(error)) {
              if (error.code === 12002)
                throw new Error(error.message)
            }
            else {
              throw new Error(error)
            }
          }
        } while (second <= 8)
        throw new Error('')
      },
    },
    guards: {
      hasOrderRes: (context, event: any) => {
        return !!event.data;
      },

      isConflict: (context, event: any) => {
        if (event.data.code === 12006)
          return false;

        return true;
      },
    },
    delays: {},
  })

  const states = reactiveComputed(() => {
    const s = machine.state.value;
    const isRequest = s.hasTag('request');
    const isSameAddress = s.context.orderPreSubmitCode === 12001;
    const isConfirm = s.hasTag('confirm') || s.hasTag('request');
    const isRefresh = s.matches('onShowSellSuccessModal');
    const isConflict = s.matches('conflict');
    const showSellRefPopup = s.hasTag('orderQueryResult');

    return {
      isRequest,
      isConfirm,
      isSameAddress,
      showSellRefPopup,
      isRefresh,
      isConflict,
    };
  });

  watch(machine.state, (state) => {
  })

  return {
    machine,
    states,
  }
})
