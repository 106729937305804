<script setup lang="ts">

import {useVModel} from '@vueuse/core';

const props = defineProps<{
  type: number | string,
  mode: any[]
}>()
const emits = defineEmits<{
  (e: 'update:type', data: boolean): void
}>()
const type = useVModel(props, 'type',emits)
</script>

<template>
  <div class="check-box">
    <div
      class="text-#8098bf"
      :class="{'text-[#13192e] bg-[#8098bf]':type === i.value, 'hover:bg-[#2e3f73] hover:text-[#e2e8f2]':type !== i.value}"
      @click="type = i.value"
      :data-test-id="`khss${k}${i.value}`"
      v-for="(i,k) in props.mode"
    >
      {{ i.name }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.check-box {
  --uno: ' border-solid border-[1.5px] border-#8098bf rounded-[10px] text-text-info flex flex-row overflow-hidden';
  --uno: 'flex-center items-stretch';
  --uno: 'lt-mobile:flex lt-mobile:items-center lt-mobile:justify-around';

  > div {
    --uno: 'text-14px cursor-pointer flex-center min-w-86px h-[32px]';
    --uno: 'lt-mobile:flex-1 lt-mobile:leading-[normal]';
    --uno: 'first-child:rounded-bl-[8px] first-child:rounded-tl-[8px] last-child:rounded-br-[8px] last-child:rounded-tr-[8px]';
    --uno: 'laptop:hover:text-#E2E8F2';
    --uno: 'lt-tablet:h-26px lt-tablet:min-w-40px ';
  }
}
</style>
