<script lang="ts">
import { useFreeze } from './hooks/useFreeze'
import { useResource, useResourceTypeText } from '~/composables/tron'
import { PromptModel } from '~/components/modal'

export interface TFreeDetails {
  orderDetail: Api.GetDataItem<typeof apis.orderTrades>
  sellAmount: number
  mode: number
  multiAddress: string
}
export enum EFreezeType {
  Short,
  Enough,
}

export interface IProps {
  detail: TFreeDetails
  close: () => void
}
</script>

<script setup lang="ts">
const props = defineProps<IProps>()
const { orderDetail } = props.detail
const { resourceType: ResourceType } = useResource()
const resTypeText = useResourceTypeText(toRef(() => props.detail.orderDetail.resource_type))

const {
  // isMultiSign,
  title,
  maxTRX,
  setMaxTrx,
  freezeInfo,
  formData,
  needExchange,
  onChange,
  onSubmit,
  resourceType,
  loading,
  remaineResource,
  freezeResult,
  modalContent,
} = useFreeze(props)
</script>

<template>
  <div class="freeze">
    <ASpin :spinning="loading.is">
      <AForm class="z-form antd-cover__bubble-help-form">
        <div class="text-[24px] text-center mb-[20px] lt-mobile:text-[22px]">{{ title }}</div>
        <I18nT class="text-md text-primary mb-[22px] leading-8" keypath="el0tG9b0UgtgKcsNj2Ju9" tag="div">
          <template #stake>
            <a class="underline font-bold" href="https://coredevs.medium.com/what-is-stake-2-0-e04f59b948a6" target="_blank" data-test-id="ruhN">Stake2.0</a>
          </template>
          <template #resType>{{ resTypeText }}</template>
        </I18nT>

        <AFormItem class="z-form-item" :labelCol="{ span: 24 }">
          <template #label>
            <div class="lt-mobile:text-14px">{{ $t('yv4z6Vj2SkGnRe0YxErPM') }}：</div>
            <div class="text-text-blue font-bold flex cursor-pointer lt-mobile:text-12px" data-test-id="2a1o" @click="setMaxTrx">
              {{ $t("KOTT-xrZ1UMpWuu1NIpIi") }}({{ formatAmount(maxTRX, 3) }} )TRX
              <ATooltip class="z-tooltip" :title="$t('MFcj2GE8iqmF5AD5gxdiT')">
                <span>
                  <i class="icon lt-mobile:text-18px" />
                </span>
              </ATooltip>
            </div>
          </template>
          <AInputNumber
            v-model:value="formData.amount"
            v-bind="inputMoney"
            :controls="false"
            style="width: 100%"
            :precision="0"
            :placeholder="$t('ds2XR_TwurRKPtCqn0W9c')"
            class="ant-cover__input-number-hidden-addon-after"
            @change="onChange"
          >
            <template #addonAfter>
              <span class="text-primary">TRX</span>
            </template>
          </AInputNumber>

          <p v-if="formData.amount" class="text-color-error">
            ≈ {{ formatAmount(freezeInfo.availableResource, 0) }}
            {{ $t(ResourceType[orderDetail?.resource_type].label) }}
          </p>
        </AFormItem>
        <div style="border-top: 1px dashed #13192e" class="my-[15px]" />

        <div class="text-primary text-md mb-[31px]">
          <p v-if="detail.mode === EFreezeType.Short" class="flex-between">
            <span>
              {{ $t("F2_rCxsz2ON3eLV9udePx") }}{{ $t(ResourceType[orderDetail?.resource_type].label) }}
              <i class="i-local:question.svg text-primary text-xl" />
              ：
            </span>

            <span>{{ formatAmount(freezeInfo.needResource, 0) }}({{ $t("O-60Sa7ybr9ENdF7fbqYW") }}{{ formatAmount(needExchange, 0) }})</span>
          </p>
          <div class="flex-between">
            <span>
              {{ $t("c6vaK9pK1DwwXjVHrPTpX")
              }}{{ $t(ResourceType[orderDetail?.resource_type].label) }}
              <i class="i-local:question.svg text-primary text-xl" />
              :
            </span>
            <span class="text-text-green font-bold">{{
              formatAmount(remaineResource[resourceType], 0)
            }}</span>
          </div>
        </div>

        <div class="flex gap-[20px]">
          <AButton class="cancel-btn flex-1" data-test-id="k66s" @click="props?.close()">{{ $t('3Sy5Ym3mSx7yphQbJCdPo') }}</AButton>

          <AButton
            class="comfirm-btn flex-1"
            :disabled="Number(formData.amount) < 0"
            data-test-id="zwcm"
            @click="onSubmit"
          >
            {{ $t('tyv0SatMpCJkxg6oLqddv') }}
          </AButton>
        </div>
      </AForm>
    </ASpin>
    <PromptModel v-model:open="freezeResult" :content="modalContent" />
  </div>
</template>

<style scoped lang="scss">
:deep(.ant-form-item.z-form-item .ant-form-item-label label){
  height: 40px;
}

.freeze {
  .z-form {
    .z-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
