<script setup lang="ts">
import { useActivity } from '~/composables/useActivity'
import { useSwichLocale } from '~/locales'

const { locale } = useSwichLocale()
const { states, onReceive, onRule, receiveList, activityConfig, enabled, isLoading } = useActivity()

const slidesToShow = computed(() => {
  const length = receiveList.value.length
  return length >= 4 ? 4 : length
})
</script>

<template>
  <div v-if="enabled" class="activity-pc ">
    <div class="grid gap-12px">
      <div class="flex items-center">
        <img v-if="locale === 'zh'" src="@/assets/imgs/activity-pc-ch.svg" class="w-[190px] mt--5px mb--10px ml--8px">
        <img v-else src="@/assets/imgs/activity-pc-en.svg" class="w-[190px] mt--5px mb--10px ml--8px">
        <div v-if="locale === 'zh'" class="flex items-center text-[18px] ml-[10px] w-[100%]">
          <img src="@/assets/imgs/activity-imtoken.svg" class="w-[120px] !inline">&nbsp;
          联名活动
        </div>
        <div v-else class="flex items-center text-[18px] ml-[10px] w-[100%]">
          Joint event of &nbsp;
          <img src="@/assets/imgs/activity-imtoken.svg" class="w-[120px] !inline">
        </div>
      </div>
      <p class="text-[14px] leading-[20px] w-[526px]">
        {{ $t('ujVxMbCptOw13TqduCAn', { limit: (activityConfig?.limit ?? 500) }) }}
        <span class="text-[#ffc52f] cursor-pointer" @click="onRule()" data-test-id="jfz6">{{ $t('y2xHRtiDxd8pAifc5ErvM') }}</span>
      </p>
      <div class="flex items-center">
        <button v-if="states.freeOrder === 2" class="receive-btn" @click="onReceive()" data-test-id="mj3r">{{ $t('7uFnjhjAwpt7tk0CbhjvF') }}</button>
        <span v-if="states.freeOrder === 2" class="text-[#ffc52f] mt-[4px] text-[18px] ml-[24px]">{{ $t('7oTIdW2je7YhwIroJKfcC') }}: {{ activityConfig?.available ?? 0 }}</span>
        <button
          v-if="states.freeOrder === 1"
          class="bg-[#266ef1] border-rd-10px w-170px h-40px text-center text-16px border-none"
        >
          {{ $t('sh8Po1QGcmHkDwVjjam2m') }}
        </button>
      </div>
    </div>
    <div v-if="receiveList">
      <ACarousel
        autoplay :autoplaySpeed="2000" :slidesToShow="slidesToShow" :dots="false"
        dotPosition="right"
        class="list"
      >
        <div v-for="(i, index) in receiveList" :key="index" class="!flex items-center justify-between">
          <p>
            {{ $d(i.create_time * 1000, "hm") }}
            <span class="ml-12px">{{ desensitization(i.receive_address, 4, -4) }} {{ $t('is6Kqxb54vyqpJi2sVuNt') }}</span>
          </p>
          <a class="hover:underline" :href="`https://tronscan.org/#/transaction/${i.frozen_tx_id}`" target="_blank" data-test-id="2fCL">{{ $t('5wmY4C7tFhssJVxS_0i1f') }}</a>
        </div>
      </ACarousel>
    </div>

    <div v-else class="list text-center flex-center">
      <i v-if="isLoading" class="i-svg-spinners:180-ring-with-bg text-text-blue text-20px" />
      <div v-else class="text-text-info">{{ $t('4YitynJpHsBfBCeEWw11') }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.activity-pc{
  --uno: 'flex items-normal p-30px border-rd-30px text-#dee5ef text-#e2e8f2';

  height: 230px;
  background: linear-gradient(225deg, #192038, #2e3f74);

  .receive-btn{
    --uno: 'bg-[#266ef1] border-rd-10px w-170px h-40px text-center text-16px cursor-pointer';

    border: none;

    &:hover{
      background: linear-gradient(90deg,#2680EB,#00C1A5) no-repeat;
    }
  }

  .list{
    --uno: 'w-267px h-160px ml-25px mr-20px py-10px px-12px border-rd-15px p-16px text-14px text-#8098bf';

    height: 160px;
    line-height: 35px;
    background: linear-gradient(90deg, rgb(37 51 94 / 100%) 0%, rgb(32 44 82 / 100%) 83%);
  }
}
</style>
