<script  lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { reactiveComputed, useEventBus } from '@vueuse/core'
import type { ColumnsType } from 'ant-design-vue/es/table'
import RecycleResoure from './RecycleResoure.vue'
import ListEmpty from './ListEmpty.vue'
import { tron } from '~/utils/tron-v2'

function columns(): ColumnsType {
  return [
    {
      title: $t('C9S88skdPmo6LY9FZEwhe'),
      dataIndex: 'to',
    },
    {
      title: $t('sfpPocgFk6Fedey02xY03'),
      dataIndex: 'types',
      align: 'left',
    },
    {
      title: $t('yv4z6Vj2SkGnRe0YxErPM'),
      dataIndex: 'balance',
      align: 'left',
    },
    {
      title: $t('6-8pimw78tWCseb1foUaM'),
      dataIndex: 'expireTime',
      align: 'left',
    },
    {
      title: $t('STNImr9TGrFnnhbFJ9FrT'),
      dataIndex: 'operationTime',
      align: 'left',
    },
    {
      title: $t('Zoxdb3CKEIj7JrZH5gr63'),
      dataIndex: 'operation',
      align: 'center',
    },
  ]
}

function useAgentTable() {
  const { isConnected, account } = tronComp.useAccountCurrent()
  const pagination = reactiveComputed(() => {
    return {
      page: 1,
      pageSize: 10,
    }
  })
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: ['agency-orders', pagination, () => account.address],
    enabled: isConnected,
    queryFn: () => {
      return tron.fetchAgencyOrders({
        address: account.address,
        limit: pagination.pageSize,
        start: (pagination.page - 1) * pagination.pageSize,
        type: 2,
      })
    },
  })

  const listData = computed(() => data.value?.data.data)
  const total = computed(() => data.value?.data.total ?? 0)
  const loading = computed(() => isConnected.value && isLoading.value || isFetching.value)

  return {
    listData,
    refetch,
    pagination,
    total,
    isLoading: loading,
  }
}
</script>

<script setup lang="ts">
const { isConnected } = tronComp.useAccountCurrent()
const { listData, pagination, total, refetch, isLoading } = useAgentTable()
const visible = useBoolean()
const record = ref<any>()
const bus = useEventBus<string>('SWITCH-WALLET')

// 监听切换钱包，关闭弹窗
bus.on(() => {
  visible.setFalse()
})

function open(row: any) {
  record.value = row
  visible.setTrue()
}

watch(visible, (visible) => {
  if (!visible)
    refetch()
})
</script>

<template>
  <div class="agent-table">
    <ATable
      v-if="$bp.tablet"
      :loading="isLoading"
      class="ant-cover__basic-table define-both-sides-padding"
      :columns="columns()"
      :data-source="listData"
      :pagination="false"
    >
      <template #emptyText>
        <ListEmpty :class="{ invisible: isLoading }" :isConnected="isConnected" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'to'">
          <span v-if="$bp.atTablet || $bp.atTabletl">{{ desensitization(record.receiverAddress, 4, -4) }}</span>
          <span v-else>{{ record.receiverAddress }}</span>
        </template>
        <template v-if="column.dataIndex === 'types'">
          {{ formatAmount(record.resourceValue, 0)
          }}{{
            record.resource === 1
              ? $t("A5FX7Ej5VR2oi-ftUBpb2")
              : $t("LjTQjhSPV9OCIvMChcWPR")
          }}
          <span
            v-if="record.lockResourceValue > 0"
            :style="{ whiteSpace: 'nowrap' }"
          >({{ formatAmount(record.lockResourceValue, 0)
          }}{{ $t("vDDKuBe5Sa2VppUt3UGaz") }})</span>
        </template>
        <template v-if="column.dataIndex === 'balance'">
          {{ formatAmount(record.balance / 10 ** 6, 0) }} TRX
        </template>
        <template v-if="column.dataIndex === 'expireTime'">
          {{ record.expireTime ? $d(record.expireTime, "full") : "-" }}
        </template>
        <template v-if="column.dataIndex === 'operationTime'">
          {{ record.operationTime ? $d(record.operationTime, "full") : "-" }}
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <AButton
            type="primary"
            :disabled="record.lockBalance >= record.balance"
            :data-test-id="`o1m5${record.order_no}`"
            @click="open(record)"
          >
            {{ $t("7EbqyptKn7-51xkvZ3ztL") }}
          </AButton>
        </template>
      </template>
    </ATable>

    <div v-else class="mb-containar">
      <div v-if="listData?.length" class="mb-panel">
        <div
          v-for="(record, index) in listData"
          :key="index"
          class="agent-panel"
        >
          <div class="header">
            <div class="left">
              {{ record.operationTime ? $d(record.operationTime, "full") : "-" }}
            </div>
            <div class="right">
              <AButton
                type="primary"
                :disabled="record.lockBalance >= record.balance"
                data-test-id="s985"
                @click="open(record)"
              >
                {{ $t("7EbqyptKn7-51xkvZ3ztL") }}
              </AButton>
            </div>
          </div>
          <div class="body">
            <div class="flex justify-between mb-[5px]">
              <span>
                {{ record.resource === 1 ? $t("YUG0RoDj9Kcw8PtVRPR9Y") : $t("IgN1EoVRFTDyCVLbFG-oT") }}：
                <span class="text-text-green">{{ formatAmount(record.resourceValue, 0) }}</span>
              </span>
              <span>
                {{ $t("ARbjMQoV4EbjhPF7nEiU7") }}
                <span class="text-text-green">{{ record.balance / 1000000 }}</span>TRX
              </span>
            </div>
            <div class="flex justify-between mb-[5px]">
              <span>{{ $t("Mf388nzvw-lHqmNA3Mp3p") }}{{ record.expireTime ? $d(record.expireTime, "full") : "-" }}</span>
            </div>
            <div class="flex justify-between">
              <span>{{ $t("cXjqP30GVLnNQZ057kxRt") }}{{ record.receiverAddress }}</span>
            </div>
          </div>
        </div>
      </div>
      <ListEmpty v-else :isConnected="isConnected" />
    </div>

    <div class="pagination-wrapper">
      <APagination
        v-model:current="pagination.page"
        v-model:page-size="pagination.pageSize"
        class="ant-cover__basic-pagination"
        :disabled="isLoading"
        :total="total"
        :hideOnSinglePage="true"
        :showSizeChanger="false"
        @change="refetch()"
      />
    </div>

    <AModal
      v-model:open="visible.is"
      wrapClassName="ant-cover__basic-modal"
      :title="$t('zN4TLdI0dZ8jP2GwC_m0Y')"
      :width="540"
      destroyOnClose
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <RecycleResoure :formData="record" :close="visible.setFalse" @refetch="refetch()" />
    </AModal>
  </div>
</template>

<style scoped lang="scss">
.ant-btn-primary:disabled{
  color: #8098bf;
  background-color: #2e3f73;
  border-color: transparent;
}

:deep(.ant-table-cell){
  font-size: 16px;
}

.mb-containar {
  .agent-panel {
    padding: 0 12px;
    background-color: #1a2344;

    .header {
      --uno: 'text-primary';

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px 8px;
      margin: 0 -12px;
      background-color: #202c52;

      .left {
        display: flex;
        align-items: center;
      }

    }

    .right {

      a {
        --uno: 'text-text-light';
      }

      .pay-detail-mb {
        --uno: 'text-text-light';

        margin-right: 8px;
        cursor: pointer;
      }
    }

  }

  .body {
    --uno: 'text-text py-20px text-14px';

    span {
      &:nth-child(2n) {
        text-align: right;
      }

      i {
        color: #707582;
      }

      em {
        font-style: normal;
        color: #707582;
      }
    }
  }
}
</style>
