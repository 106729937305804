<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import type { ColumnsType } from 'ant-design-vue/es/table'
import Copy from '~/components/copy'

const { account } = tronComp.useAccountCurrent()
const visible = useBoolean()
type TDetail = Api.GetDataItem<typeof apis.myPayOrder>

const record = ref<TDetail>()
const _resourceType = ref<number>(0)

function open(options: TDetail) {
  record.value = options
  _resourceType.value = options.resource_type
  visible.setTrue()
}
// 表格配置
function columns(): ColumnsType {
  return [
    {
      title: record.value?.resource_type
        ? $t('1XEcV3JaaEExJi99kx8HV')
        : $t('fg-3ruckxHm-hz0RIne0j'),
      dataIndex: 'resource_value',
      align: 'left',
    },
    {
      title: $t('OFoavcDI4T1WSJe3uWawZ'),
      dataIndex: 'freeze_time',
      align: 'left',
    },
    {
      title: $t('FunqFL9-E5Gg3U4L2OXuF'),
      dataIndex: 'expire_time',
      align: 'left',
    },
    {
      title: $t('Zoxdb3CKEIj7JrZH5gr63'),
      dataIndex: 'operation',
      align: 'left',
    },
  ]
}

const paginationChange = (page: number) => (pagination.page = page)

const pagination = reactive({
  page: 1,
  pageSize: 5,
})

const enabled = computed(
  () => !!unref(record) && !!record.value.order_no && !!account.address,
)

const queritr = useQuery({
  queryKey: [apis.subOrderListByAddress.id, pagination, record],
  enabled,
  refetchOnWindowFocus: false,
  staleTime: 10 * 1000,
  queryFn: () =>
    apis.subOrderListByAddress({
      trx_address: account.address,
      order_parent_no: record.value?.order_no,
      page: pagination.page,
      page_size: pagination.pageSize,
    }),
})

const listData = computed(() => queritr?.data.value?.list)
const total = computed(() => queritr?.data.value?.pagination.total)

defineExpose({
  open,
  close: visible.setFalse,
})
</script>

<template>
  <AModal
    v-model:open="visible.is"
    wrapClassName="ant-cover__basic-modal ant-cover__basic-modal-table"
    :title="$t('6uSlooIREkQF6k1hgnuHF')"
    :footer="null"
    closable
    :loading="queritr.isLoading.value"
    :destroyOnClose="true"
    :width="700"
  >
    <div class="box">
      <AConfigProvider :theme="{ components: { Table: { colorBgContainer: '#202c52', colorFillAlter: '#2e3f73' } } }">
        <ATable
          class="ant-cover__basic-table define-both-sides-padding [--both-sides-padding:26px]"
          :size="client.isMobile ? 'small' : 'large'"
          :dataSource="listData"
          :loading="queritr.isLoading.value"
          :columns="columns()"
          :pagination="{
            position: ['bottomCenter'],
            pageSize: pagination.pageSize,
            total,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: paginationChange,
          }"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'resource_value'">
              {{ formatAmount(record.resource_value, 0) }}
            </template>
            <template v-if="column.dataIndex === 'freeze_time'">
              {{ $d(record?.freeze_time * 1000 ?? 0, "full") }}
            </template>
            <template v-if="column.dataIndex === 'expire_time'">
              {{ $d(record?.expire_time * 1000 ?? 0, "full") }}
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <div class="flex items-center">
                <a
                  :href="`https://tronscan.org/#/transaction/${record.frozen_tx_id}`"
                  target="_blank"
                  data-test-id="JOsd"
                  :title="$t('qcuRm9dk5r0nPtepdaaEA')"
                >
                  <i class="i-material-symbols-light:preview-outline-sharp text-text-green text-24px " />
                </a>
                <Copy :content="record.frozen_tx_id" />
              </div>
            </template>
          </template>
        </ATable>
      </AConfigProvider>
    </div>
  </AModal>
</template>

<style scoped lang="scss">
.box {
  margin: 0 -40px -30px;

  @media bp-lt-tablet {
    margin: 0 -20px -10px;
  }
}

:deep(.ant-table-wrapper .ant-table){
  background: transparent;
}
</style>
