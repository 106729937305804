<script setup lang="ts">
// RightExtra
import { useEventBus } from '@vueuse/core'
import VoteModal from '../VoteModal.vue'
import { useC2cBuyMachine } from '../buy/machine/useC2cBuyMachine'

const { isConnected } = tronComp.useAccountCurrent()
const { machine, states } = useC2cBuyMachine()
const bus = useEventBus<string>('SELF_HELP_ORDER')
const walletBus = useEventBus<string>('SWITCH-WALLET')
const VoteModalRef = ref<InstanceType<typeof VoteModal>>()

const sort = ref(1) // 订单 sort
const visible = useBoolean()
function onSelect(value: string) {
  bus.emit(value)
}
async function toVote() {
  if (!unref(isConnected))
    await globalModal.ConnectWallet?.open()

  visible.setTrue()
}

// 监听切换钱包，关闭弹窗
walletBus.on(() => {
  visible.setFalse()
})
</script>

<template>
  <div class="RightExtra">
    <div class="right-extra">
      <AConfigProvider :theme="{ components: { Select: { controlHeight: $bp.ltTablet ? 32 : 40 } } }">
        <ASelect
          v-model:value="sort"
          class="sort-select"
          popupClassName="ant-cover__basic-select-dropdown"
          data-test-id="BvxQ"
          @select="onSelect"
        >
          <ASelectOption :value="1">{{ $t("9_IDEgQOqd_zcBWsuQVJW") }}</ASelectOption>
          <ASelectOption :value="0">{{ $t("LME4rpblqaOadzc90PSgq") }}</ASelectOption>
          <ASelectOption :value="2">{{ $t("xp0CzGweduUVTqU7xh_m-") }}</ASelectOption>
          <ASelectOption :value="3">{{ $t("wQpUzwaW9_GSVpbx2hymH") }}</ASelectOption>
        </ASelect>
      </AConfigProvider>

      <AButton class="comfirm-btn  ml-15px w-100px h-40px! border-none! font-normal! lt-tablet:!hidden" data-test-id="huln" @click="toVote">
        <i class="i-local:tron-rank?mask img mr-5px" />
        {{ $t("P8YJIj3x7WLfIy9i8UhbB") }}
      </AButton>
    </div>

    <AModal
      v-model:open="visible.is"
      wrapClassName="ant-cover__basic-modal cover-modal-btn"
      :title="$t('rpYpPRvGzQYZxSPvArvT7')"
      :footer="null"
      destroyOnClose
      @cancel="visible.setFalse()"
    >
      <VoteModal ref="VoteModalRef" :close="visible.setFalse" />
    </AModal>
  </div>
</template>

<style scoped lang="scss">
.right-extra {
  display: flex;
  align-items: center;
}

.sort-select {
  width: 120px;

  @media bp-lt-tablet {
    width: 110px;
  }

  :deep(.ant-select-selector) {
    font-size: 16px;
    color: #8098bf;
    border: 2px solid #303c6a !important;
    border-radius: 10px !important;

    @media bp-lt-tablet {
      font-size: 14px;
    }
  }

  :deep(.ant-select-arrow) {
    color: #8098bf;
  }
}
</style>
