import { createMachine } from 'xstate'
import type { TDiscount } from '../hooks/useBuy'

interface IContext {
  formData: any
  payType: number
  paymentAddress: string | undefined
  discountInfo: TDiscount
  toAddress: string
  paymentUrl: string
}

export const machine = createMachine({
  id: 'c2cBuyMachine',
  initial: 'idle',
  states: {
    'idle': {
      always: {
        target: 'confirm',
        cond: 'isPc',
      },
      on: {
        OPEN: {
          target: 'confirm',
        },
      },
    },
    'confirm': {
      on: {
        CLOSE: {
          target: 'idle',
        },
        BUY: {
          target: 'info-confirm',
          actions: {
            type: 'setInfo',
          },
        },
      },
    },
    'info-confirm': {
      on: {
        PAY: [
          {
            target: 'tronlink',
            cond: 'isTronlink',
            internal: false,
          },
          {
            target: 'TTpay',
            cond: 'isTtpay',
            internal: false,
          },
          {
            target: 'Balance',
            cond: 'isBalance',
          },
        ],
        CLOSE: {
          target: 'confirm',
        },
      },
    },
    'tronlink': {
      entry: {
        type: 'setInfo',
      },
      initial: 'idle',
      states: {
        'idle': {
          always: {
            target: 'tronlink-order-precreating',
          },
        },
        'tronlink-order-precreating': {
          invoke: {
            src: 'precreateTronlinkOrder',
            id: 'precreateTronlinkOrder',
            onDone: [
              {
                target: 'tronlink-create-order',
              },
            ],
            onError: [
              {
                target: '#c2cBuyMachine.confirm',
                actions: {
                  type: 'requestError',
                },
              },
            ],
          },
          tags: ['request', 'tronlink-request'],
        },
        'tronlink-create-order': {
          invoke: {
            src: 'createTronlinkOrder',
            id: 'createTronlinkOrder',
            onDone: [
              {
                target: 'tronlink-query-order',
              },
            ],
            onError: [
              {
                target: '#c2cBuyMachine.confirm',
                actions: {
                  type: 'requestError',
                },
              },
            ],
          },
          tags: ['request', 'tronlink-request'],
        },
        'tronlink-query-order': {
          invoke: {
            src: 'queryTronlinkOrder',
            id: 'invoke-6coj5',
          },
          tags: ['request', 'tronlink-request', 'querying-tronlink'],
          on: {
            END: {
              target: 'order-results',
            },
          },
        },
        'order-results': {
          on: {
            OK: {
              target: '#c2cBuyMachine.idle',
            },
          },
        },
      },
    },
    'TTpay': {
      entry: {
        type: 'setInfo',
      },
      initial: 'idle',
      states: {
        'idle': {
          always: {
            target: 'ttpay-create-order',
          },
        },
        'ttpay-create-order': {
          invoke: {
            src: 'createTtpayOrder',
            id: 'createTtpayOrder',
            onDone: [
              {
                target: 'ttpay-query-order',
                actions: [
                  {
                    type: 'toPayment',
                  },
                  {
                    type: 'setPayment',
                  },
                ],
              },
            ],
            onError: [
              {
                target: '#c2cBuyMachine.confirm',
                actions: {
                  type: 'requestError',
                },
              },
            ],
          },
          tags: ['request', 'ttpay-request'],
        },
        'ttpay-query-order': {
          invoke: {
            src: 'queryTtpayOrder',
            id: 'invoke-5co6c',
          },
          tags: ['request', 'ttpay-request', 'querying-ttpay'],
          on: {
            END: {
              target: 'order-results',
            },
            STOP: {
              target: '#c2cBuyMachine.idle',
            },
            KEEP: {
              actions: {
                type: 'toPayment',
              },
              internal: true,
            },
          },
        },
        'order-results': {
          on: {
            OK: {
              target: '#c2cBuyMachine.idle',
            },
          },
        },
      },
    },
    'Balance': {
      initial: 'idle',
      states: {
        'idle': {
          always: {
            target: 'balance-create-order',
          },
        },
        'balance-create-order': {
          invoke: {
            src: 'createBalanceOrder',
            id: 'createBalanceOrder',
            onError: [
              {
                target: '#c2cBuyMachine.info-confirm',
              },
            ],
            onDone: [
              {
                target: 'order-results',
              },
            ],
          },
          tags: [
            'request',
            'balance-request',
          ],
        },
        'order-results': {
          on: {
            OK: {
              target: '#c2cBuyMachine.idle',
            },
          },
        },
      },
    },
  },
  schema: {
    events: {} as
      | {
        type: 'PAY'
        formData: any
        paymentAddress: string
        discountInfo: any
        payType: number
        toAddress: string
        data: any
        paymentMethod: 'dapp' | 'ttpay' | 'balance'
      }
      | {
        type: 'OPEN'
        formData?: any
        paymentAddress?: string
        discountInfo?: any
        payType?: number
        toAddress?: string
        data?: any
        paymentMethod?: 'dapp' | 'ttpay' | 'balance'
      }
      | {
        type: 'CLOSE'
        formData?: any
        paymentAddress?: string
        discountInfo?: any
        payType?: number
        toAddress?: string
        data?: any
        paymentMethod?: 'dapp' | 'ttpay' | 'balance'
      } | {
        type: 'BUY'
        formData?: any
        paymentAddress?: string
        discountInfo?: any
        payType?: number
        toAddress?: string
        data?: any
        paymentMethod?: 'dapp' | 'ttpay' | 'balance'
      }
      | {
        type: 'END'
        formData?: any
        paymentAddress?: string
        discountInfo?: any
        payType?: number
        toAddress?: string
        data?: any
        paymentMethod?: 'dapp' | 'ttpay' | 'balance'
      }
      | {
        type: 'OK'
        formData?: any
        paymentAddress?: string
        discountInfo?: any
        payType?: number
        toAddress?: string
        data?: any
        paymentMethod?: 'dapp' | 'ttpay' | 'balance'
      }
      | {
        type: 'KEEP'
        formData?: any
        paymentAddress?: string
        discountInfo?: any
        payType?: number
        toAddress?: string
        data?: any
        paymentMethod?: 'dapp' | 'ttpay' | 'balance'
      } | {
        type: 'STOP'
        formData?: any
        paymentAddress?: string
        discountInfo?: any
        payType?: number
        toAddress?: string
        data?: any
        paymentMethod?: 'dapp' | 'ttpay' | 'balance'
      },
  },
  predictableActionArguments: true,
  preserveActionOrder: true,
  context: ({} as unknown) as IContext,
  tsTypes: {} as import('./c2cBuyMachine.typegen').Typegen0,
})
