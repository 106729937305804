<script setup lang="ts">
import type { ColumnsType } from 'ant-design-vue/es/table'
import { useQuery } from '@tanstack/vue-query'
import { useEventBus } from '@vueuse/core'
import SellPopup from './SellPopup.vue'
import { useC2cSellMachineProvide } from './machine/useC2cSellMachine'
import {calcAPY, timeFn} from '~/utils';
import emptyImg from '@/assets/imgs/no-trade.png'

const props = defineProps(['resourceType'])
const SellPopupGather = defineAsyncComponent(
  () => import('./SellPopupGather.vue'),
)

const { dataList, isLoading, pagination, sort, total, refetch } = useSelfHelpOrder()
const bus = useEventBus<string>('SELF_HELP_ORDER')
const walletBus = useEventBus<string>('SWITCH-WALLET')
const SellPopupRef = ref<InstanceType<typeof SellPopup>>()
const orderDetail = ref<Api.GetDataItem<typeof apis.orderTrades>>()
const muitiSingAddress = ref('')

const { isConnected } = tronComp.useAccountCurrent()
const tableColumns = computed<ColumnsType>(() => {
  return [
    {
      title: $t('oOQP0IsDfusZMhnvgoc6F'),
      dataIndex: 'max_payout',
      align: 'left',
    },
    {
      title: $t('oZbdPHvMHlg0vTnvAKSqP'),
      dataIndex: 'min_payout',
      align: 'left',
    },
    {
      title: $t('qcqH8cZUrI5QfSaWTTKtx'),
      dataIndex: 'price_in_sun',
      align: 'left',
      responsive: ['md'],
    },
    {
      title: $t('W20-olrMXY1b7esJc7-oz'),
      dataIndex: 'rent_duration',
      align: 'left',
      responsive: ['md'],
    },
    {
      title: $t('Zoxdb3CKEIj7JrZH5gr63'),
      dataIndex: 'actions',
      align: 'center',
      width: '80px',
    },
  ]
})

const paginationChange = (page: number) => (pagination.page = page)
const { machine, states } = useC2cSellMachineProvide()

function useSelfHelpOrder() {
  const pagination = reactive({
    pageSize: 5,
    page: 1,
  })
  const sort = ref(1)
  const resourceType = computed(() => props.resourceType)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [apis.orderTrades.id, pagination, sort, resourceType],
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 10,
    queryFn: () =>
      apis.orderTrades({
        page: pagination.page,
        page_size: pagination.pageSize,
        sort: sort.value,
        resource_type: resourceType.value,
      }),
  })

  const dataList = computed(() => data.value?.list ?? [])
  const total = computed(() => data.value?.pagination.total ?? 0)

  return {
    dataList,
    pagination,
    isLoading,
    total,
    sort,
    refetch,
  }
}

async function openSellPopup(record: any) {
  if (!toValue(isConnected))
    await globalModal.ConnectWallet?.open()

  if (!record) {
    aMessage.info($t('HhznB0JXVq8V80cKfkmnb'))
    return
  }

  orderDetail.value = record
  machine.send('OPEN')
}

watch(
  () => [states.isRefresh, states.isConflict],
  (val) => {
    if (val.some(i => !!i)) {
      refetch()
      if (dataList.value.length === 0 && pagination.page != 1)
        pagination.page = 1
    }
  },
)

function listener(e: string) {
  sort.value = Number(e)
  pagination.page = 1
}
bus.on(listener)

walletBus.on(() => {
  machine.send('CLOSE')
})
</script>

<template>
  <div class="self-help-order">
    <ATable
      class="order-table ant-cover__basic-table define-both-sides-padding"
      :loading="isLoading"
      :columns="tableColumns"
      :data-source="dataList"
      :pagination="{
        position: ['bottomCenter'],
        current: pagination.page,
        pageSize: pagination.pageSize,
        total,
        hideOnSinglePage: true,
        showSizeChanger: false,
        onChange: paginationChange,
      }"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'max_payout'">
          <div>
            <div class="font-bold mb-[3px] text-lg">
              {{ formatAmount(record.max_payout, 2) }} <i class="text-[0.75em] font-normal">TRX</i>
            </div>

            <ATooltip placement="topLeft">
              <template #title>
                {{ $t('uUbntvhHw8pZnqGSBHM11', {
                  count: formatAmount(record.max_freeze, 2),
                  time: timeFn(record.rent_duration, record.rent_time_unit),
                  num: formatAmount(record.max_payout, 2),
                }) }}
              </template>

              <div class="text-text-secondary lt-tablet:text-12px">
                {{ $t("ilnE_94TN12UxnmHXZfI3") }}{{ formatAmount(record.max_freeze, 2) }} TRX
              </div>
            </ATooltip>

            <ATooltip placement="topLeft">
              <template #title>
                {{
                  $t('ylToOgCdW8iifDMvef2Wp', {
                    type: record.resource_type ? $t("Mqe_hlD_yGPsAMB9oaO1D") : $t("xo7kGD9R2fV50wOIihlhL"),
                    count: formatAmount(record.max_freeze, 0),
                  })
                }}
              </template>

              <div class="text-text-secondary lt-tablet:text-12px">
                {{ record.resource_type ? $t("Mqe_hlD_yGPsAMB9oaO1D") : $t("xo7kGD9R2fV50wOIihlhL") }}：{{ formatAmount(record.max_amount, 0) }}
              </div>
            </ATooltip>

            <div class="hidden text-text-secondary lt-tablet:block lt-tablet:text-[12px]">
              {{ $t("TXG20TlIkaU_VKIj_k1Sd") }}
              {{ timeFn(record.rent_duration, record.rent_time_unit) }}
            </div>
          </div>
        </template>

        <template v-if="column.dataIndex === 'min_payout'">
          <div>
            <div class="font-bold mb-[3px] text-lg">
              {{ formatAmount(record.min_payout, 2) }} <i class="text-[0.75em] font-normal">TRX</i>
            </div>

            <ATooltip placement="topLeft">
              <template #title>
                {{ $t('ztt20Nwh1g1AkArLoogD', {
                  count: formatAmount(record.min_freeze, 2),
                  time: timeFn(record.rent_duration, record.rent_time_unit),
                  num: formatAmount(record.min_payout, 2),
                }) }}
              </template>
              <div class="text-text-secondary lt-tablet:text-12px">
                {{ $t("ilnE_94TN12UxnmHXZfI3") }}{{ formatAmount(record.min_freeze, 0) }} TRX
              </div>
            </ATooltip>

            <ATooltip placement="topLeft">
              <template #title>
                {{ $t('vBgOXUvD4TrebyJkiJtv', {
                  type: record.resource_type
                    ? $t("Mqe_hlD_yGPsAMB9oaO1D")
                    : $t("xo7kGD9R2fV50wOIihlhL"),
                  count: formatAmount(record.min_freeze, 0),
                }) }}
              </template>

              <div class="text-text-secondary lt-tablet:text-12px">
                {{ record.resource_type ? $t("Mqe_hlD_yGPsAMB9oaO1D") : $t("xo7kGD9R2fV50wOIihlhL") }}：{{ formatAmount(record.min_amount, 0) }}
              </div>
            </ATooltip>

            <div class="hidden text-text-secondary lt-tablet:text-[12px] lt-tablet:block">
              {{ $t("d6ggOrqnIdtCyuMDDHxC6") }}{{ record.price_in_sun }} <i>SUN</i>
            </div>
          </div>
        </template>

        <template v-if="column.dataIndex === 'price_in_sun'">
          <div class="mb-[3px] text-text font-bold text-lg">
            {{ record.price_in_sun }} <i class="text-[0.75em] font-normal">SUN</i>
          </div>
          <div class="text-text-secondary">
            {{ $t("mtUsxH9Rqug69EiOlNRT5") }}{{ calcAPY(record) }}
          </div>
        </template>

        <template v-if="column.dataIndex === 'rent_duration'">
          {{ timeFn(record.rent_duration, record.rent_time_unit) }}
        </template>

        <template v-if="column.dataIndex === 'actions'">
          <div class="align-middle">
            <AButton type="primary" class="lt-tablet:text-[14px]!" :data-test-id="`v3a4${record.order_no}`" @click="openSellPopup(record)">
              {{ $t("lwo61KCcFg0pRYGRGmwyk") }}
            </AButton>
            <div class="hidden text-text-secondary lt-tablet:mt-[5px] lt-tablet:block lt-tablet:text-[12px]">
              <div>{{ $t('46jM7l7b94d3ZXwwnZc') }}</div>
              <div>{{ calcAPY(record) }}</div>
            </div>
          </div>
        </template>
      </template>

      <template #emptyText>
        <div class="mt-[120px] lt-tabletl:mt-40px" :class="{ invisible: isLoading }">
          <img :src="emptyImg" class="h-[210px] lt-tabletl:h-68px">
          <p class="text-[18px] text-[#8098bf] lt-tabletl:text-14px">
            {{ $t('sy2tfJCuCzU1iFdJ52Bu') }}
          </p>
        </div>
      </template>
    </ATable>

    <AModal
      wrapClassName="ant-cover__basic-modal"
      :open="states.isConfirm"
      :title="orderDetail?.resource_type === 1 ? $t('oib_Av5xCj3SgV8Jw3ZPO') : $t('bH-rRvpXLsJxaOCNVaGcc')"
      destroyOnClose
      :footer="null"
      @cancel="machine.send('CLOSE')"
    >
      <SellPopup
        ref="SellPopupRef"
        v-model:muiti-sing-address="muitiSingAddress"
        :orderDetail="orderDetail"
      />
    </AModal>

    <SellPopupGather />
  </div>
</template>

<style scoped lang="scss">
.order-table {
  --both-sides-padding: 30px;

  @media bp-lt-tablet {
    --both-sides-padding: 16px;
  }

  :deep(.ant-table-tbody > tr > td) {
    padding-top: 10px;
    padding-bottom: 10px;

    @media bp-lt-tablet {
      padding: 10px;
    }
  }

  :deep(.ant-table){
    @media bp-tablet {
      min-height: 540px;
    }
  }

  :deep(.ant-table-pagination){
    margin-bottom: 0 !important;
  }

  // 在PC端租用时间和操作需要垂直居中n
  :deep(.ant-table-cell:nth-child(n+4)) {
    @media bp-tablet {
      vertical-align: middle;
    }
  }
}
</style>

<style lang="scss">
.ant-pagination.ant-cover__basic-pagination{
  li.ant-pagination-item,li.ant-pagination-prev{
    min-width: 28px;
    height: 30px;
  }
}
</style>
