import { imtoken } from '~/utils/imtoken'

//  改成 响应式的
export function useIsImtoken() {
  // 没有连接并且是imtoken
  if (imtoken.isImToken() && !(window as any).tronWeb)
    imtoken.showAccountSwitch()
}

// 判断是否是imtoken的环境Promise todo
export function hasImtoken() {
  new Promise((resolve, reject) => {
    if (imtoken.isImToken() && !(window as any).tronWeb)
      resolve()
    else
      reject()
  })
}
