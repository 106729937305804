import { createMachine } from 'xstate'

interface ImultiSignIfo {
  isOwnerPermission: boolean
  permissionId: number
}

interface IContext {
  orderDetail: Api.GetDataItem<typeof apis.orderTrades>
  ownerAddress: string
  resourceValue: number
  settleAddress: string
  frozenBalance: number
  skipDuplicateCheck: number
  orderPreSubmitCode: number
  multiSignIfo: ImultiSignIfo
  isMultiSign: Ref<boolean> | boolean
  resourceType: string
  lockPeriod: number
}

export const machine = createMachine({
  id: 'C2cSellMachine',
  initial: 'idle',
  states: {
    idle: {
      entry: {
        type: 'init',
      },
      on: {
        OPEN: {
          target: 'confirm',
        },
      },
    },
    confirm: {
      tags: 'confirm',
      entry: {
        type: 'resetting',
      },
      on: {
        CLOSE: {
          target: 'idle',
        },
        SUBMIT: {
          target: 'FetchOrderPredelegete',
          actions: {
            type: 'setInfo',
          },
        },
        DOUBLECHEC: {
          target: 'FetchOrderPredelegete',
          actions: {
            type: 'setInfo',
          },
        },
      },
    },
    FetchOrderPredelegete: {
      invoke: {
        src: 'fetchOrderPredelegete',
        id: 'fetchOrderPredelegete',
        onDone: [
          {
            target: 'fetchDelegateResourceSign',
            cond: 'hasOrderRes',
          },
          {
            target: 'fetchdelegateResourceServerBroadcast',
          },
        ],
        onError: [
          {
            target: 'confirm',
            cond: 'isConflict',
            actions: [
              {
                type: 'preRequestError',
              },
              {
                type: 'showErrorMessage',
              },
            ],
            internal: false,
          },
          {
            target: 'conflict',
          },
        ],
      },
      tags: 'request',
    },
    fetchDelegateResourceSign: {
      invoke: {
        src: 'fetchDelegateResourceSign',
        id: 'fetchDelegateResourceSign',
        onDone: [
          {
            target: 'orderDelegateC2C',
          },
        ],
        onError: [
          {
            target: 'confirm',
            actions: {
              type: 'showErrorMessage',
            },
          },
        ],
      },
      tags: 'request',
    },
    fetchdelegateResourceServerBroadcast: {
      invoke: {
        src: 'fetchdelegateResourceServerBroadcast',
        id: 'fetchdelegateResourceServerBroadcast',
        onDone: [
          {
            target: 'orderDelegateC2C',
          },
        ],
        onError: [
          {
            target: 'confirm',
            actions: {
              type: 'showErrorMessage',
            },
          },
        ],
      },
      tags: 'request',
    },
    conflict: {
      on: {
        OK: {
          target: 'idle',
        },
      },
    },
    orderDelegateC2C: {
      invoke: {
        src: 'orderDelegateC2C',
        id: 'orderDelegateC2C',
        onDone: [
          {
            target: 'orderQuery',
          },
        ],
        onError: [
          {
            target: "confirm",
            actions: {
              type: "showErrorMessage",
            },
            cond: 'isConflict',
          },
          {
            target: "conflict",
            actions: {
              type: "showErrorMessage",
            },
          },
        ],
      },
      tags: 'request',
    },
    orderQuery: {
      invoke: {
        src: 'orderQuery',
        id: 'orderQuery',
        onDone: [
          {
            target: 'onShowSellSuccessModal',
            actions: {
              type: 'orderQuerySuccess',
            },
          },
        ],
        onError: [
          {
            target: 'onShowSellFailModal',
          },
        ],
      },
      tags: ['orderQuerying', 'request'],
    },
    onShowSellSuccessModal: {
      tags: ['sellSuccess', 'orderQueryResult'],
      on: {
        OK: {
          target: 'idle',
        },
      },
    },
    onShowSellFailModal: {
      tags: ['sellFail', 'orderQueryResult'],
      on: {
        OK: {
          target: 'idle',
        },
      },
    },
  },
  schema: {
    events: {} as
      | { type: 'OK' }
      | { type: 'OPEN' }
      | { type: 'CLOSE' }
    | { type: 'DOUBLECHEC', skipDuplicateCheck: number }
      | {
        type: 'SUBMIT'
        orderDetail: Api.GetDataItem<typeof apis.orderTrades>
        ownerAddress: string
        resourceValue: number
        settleAddress: string
        frozenBalance: number
        skipDuplicateCheck: number
        multiSignIfo: ImultiSignIfo
        isMultiSign: boolean | Ref<boolean>
        resourceType: string
      },
  },
  predictableActionArguments: true,
  preserveActionOrder: true,
  context: ({} as unknown) as IContext,
  tsTypes: {} as import('./c2cSellMachine.typegen').Typegen0,
})
