<script lang="ts">
import type { ColumnsType } from 'ant-design-vue/es/table'
import { useQuery } from '@tanstack/vue-query'
import { reactiveComputed } from '@vueuse/core'
import ListEmpty from './ListEmpty.vue'

function columns(): ColumnsType {
  return [
    {
      title: $t('qcqH8cZUrI5QfSaWTTKtx'),
      dataIndex: 'price_in_sun',
      width: 100,
    },
    {
      title: $t('00AyXbIDrmJXyzw_UsGi2'),
      dataIndex: 'resource_value',
      width: 150,
    },
    {
      title: $t('8iK6jGXN2rkJTeFiOgeKh'),
      dataIndex: 'payout',
      align: 'left',
      width: 150,
    },
    {
      title: $t('c1R3GTGbhHGUQ-MFaNQpC'),
      dataIndex: 'rent_duration',
      align: 'left',
      width: 150,
    },
    {
      title: $t('uiB6J5wmqrVMS1jIdBREC'),
      dataIndex: 'time',
      align: 'left',
      width: 150,
    },
    {
      title: $t('Zoxdb3CKEIj7JrZH5gr63'),
      dataIndex: 'operation',
      align: 'center',
      width: 100,
    },
  ]
}

function useLatestTable() {
  const pagination = reactiveComputed(() => {
    return {
      page: 1,
      pageSize: 10,
    }
  })
  const { data, isLoading, refetch } = useQuery({
    queryKey: [apis.orderLastTrades.id, pagination],
    queryFn: () =>
      apis.orderLastTrades({
        page: pagination.page,
        page_size: pagination.pageSize,
      }),
  })
  const listData = computed(() => data.value?.list)
  const total = computed(() => data.value?.pagination.total)
  return {
    listData,
    isLoading,
    refetch,
    pagination,
    total,
  }
}
</script>

<script setup lang="ts">
const { listData, isLoading } = useLatestTable()
</script>

<template>
  <div class="latestTable">
    <ATable
      v-if="$bp.tablet"
      class="ant-cover__basic-table define-both-sides-padding"
      :loading="isLoading"
      :columns="columns()"
      :data-source="listData"
      :pagination="false"
    >
      <template #emptyText>
        <ListEmpty :class="{ invisible: isLoading }" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'price_in_sun'">
          <div class="sun">{{ record.price_in_sun }} sun</div>
        </template>

        <template v-if="column.dataIndex === 'resource_value'">
          <div class="resouce">
            <i v-if="record.resource_type === 0" class="icon i-local:tron-bw2-s2 text-[20px]" />
            <i v-if="record.resource_type === 1" class="icon i-local:energy-s2 text-[20px]" />
            {{ formatAmount(record.resource_value, 0) }}
          </div>
        </template>

        <template v-if="column.dataIndex === 'payout'">
          <span class="payout text-bg-active font-semibold">{{
            formatAmount(record.settle_amount, 2)
          }}</span>
        </template>

        <template v-if="column.dataIndex === 'rent_duration'">
          {{ timeFn(record.rent_duration, record.rent_time_unit) }}
        </template>

        <template v-if="column.dataIndex === 'time'">
          {{ record.freeze_time ? $d(record.freeze_time * 1000, "full") : "-" }}
        </template>

        <template v-if="column.dataIndex === 'operation'">
          <a
            class="!text-bg-active"
            :href="`https://tronscan.org/#/transaction/${record.frozen_tx_id}`"
            target="_blank"
            data-test-id="Kgjl"
          >{{ $t("vIVz1OAClDxc6NJlYJkoO") }}</a>
        </template>
      </template>
    </ATable>
    <div v-else class="mb-containar">
      <div v-if="listData?.length" class="mb-panel">
        <div
          v-for="(record, index) in listData"
          :key="index"
          class="latest-panel"
        >
          <div class="header">
            <div class="left">
              {{ record.freeze_time ? $d(record.freeze_time * 1000, "full") : "-" }}
            </div>
            <div class="right">
              <a :href="`https://tronscan.org/#/transaction/${record.frozen_tx_id}`" target="_blank" data-test-id="G21a">{{ $t("vIVz1OAClDxc6NJlYJkoO") }}</a>
            </div>
          </div>
          <div class="body">
            <div class="flex justify-between mb-5px">
              <span>{{ $t("d6ggOrqnIdtCyuMDDHxC6") }}{{ record.price_in_sun }} sun</span>
              <span>{{ record.resource_type === 1 ? $t("YUG0RoDj9Kcw8PtVRPR9Y") : $t("IgN1EoVRFTDyCVLbFG-oT") }}：{{ formatAmount(record.resource_value, 0) }}</span>
            </div>
            <div class="flex justify-between">
              <span>{{ $t("0O0n9scxr4Ls7YVQktnV1") }}<span class="text-text-green">{{ formatAmount(record.settle_amount, 2) }}</span>TRX</span>
              <span>{{ $t("p0TcyLOMXm3GmV5tWIlau") }}<span>
                {{ timeFn(record?.rent_duration, record?.rent_time_unit) }}
              </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <ListEmpty v-else />
    </div>
  </div>
</template>

<style scoped lang="scss">
.mb-containar {
  .latest-panel {
    padding: 0 16px;
    background-color: #1a2342;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 10px 12px 8px;
      margin: 0 -16px;
      background-color: #202c52;

      .left {
        --uno: 'text-primary';

        display: flex;
        align-items: center;
      }
    }

    .right {
      .mb-btn {
        --uno: 'text-text-light';

        border: none;
      }

      a {
        --uno: 'text-text-light lt-tabletl:text-14px';
      }

      .pay-detail-mb {
        --uno: 'text-text-light mr-8px cursor-pointer';
      }
    }
  }

  .body {
    --uno: 'text-text py-20px text-14px';

    span {
      &:nth-child(2n) {
        text-align: right;
      }

      i {
        color: #707582;
      }

      em {
        font-style: normal;
        color: #707582;
      }
    }
  }
}
</style>
