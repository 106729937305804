<script setup lang="ts">
import { useAppBreakpoints } from '@peng_kai/theme/vue/index'
import SellVip from './components/SellVip.vue'
import WalletPlate from './components/WalletPlate.vue'
import FastTrad from './components/FastTrad.vue'
import BuyPopup from './components/buy/BuyPopup.vue'
import CurrentOrder from './components/current-order/CurrentOrder.vue'
import History from './components/history/index.vue'
import { useC2cBuyMachineProvide } from './components/buy/machine/useC2cBuyMachine'
import { useActivityProvider } from '~/composables/useActivity'
import { ActivityDesktop, ActivityMobile, ActivityModels } from '~/components/activity'

const showSellVip = useBoolean()
const { isConnected } = tronComp.useAccountCurrent()
const { machine, states } = useC2cBuyMachineProvide()
const { enabled } = useActivityProvider()
const BuyPopupRef = ref<InstanceType<typeof BuyPopup>>()
const bp = useAppBreakpoints()

watch(() => bp.ltTabletl, () => {
  machine.send('CLOSE')
})
</script>

<template>
  <div class="c2c-container">
    <div class="c2c-content page-content-width">
      <div class="mb-[30px] lt-tabletl:mb-[16px] " :class="isConnected ? 'c2c-box' : 'c2c-box2'">
        <!-- 移动活动 -->
        <ActivityMobile v-if="enabled" class="activity-box mb-30px" />
        <!-- PC活动 -->
        <div v-if="$bp.laptopl" class="relative sell-div">
          <ACarousel class="absolute w-full h-full" arrows>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <img src="@/assets/imgs/arrow-s2.svg">
              </div>
            </template>
            <div v-if="enabled">
              <ActivityDesktop class="activity-div" />
            </div>
            <div>
              <SellVip :class="{ '!col-start-1 !col-end-3 row-start-1': !isConnected && ($bp.atTabletl || $bp.atLaptop) }" />
            </div>
          </ACarousel>
        </div>

        <WalletPlate
          v-if="isConnected"
          class="wallet-div"
          :class="$bp.laptopl ? 'row-end-4' : 'row-end-2'"
        />
        <BuyPopup
          ref="BuyPopupRef"
          class="buy-div"
          :class="isConnected ? '' : 'row-start-1 row-end-4'"
        />
        <div class="top-buttons hidden lt-laptopl:flex lt-laptopl:justify-between">
          <AButton class="custom-button" data-test-id="5s8h" @click="machine.send('OPEN')">
            <i class="icon i-local:buy-btn text-[22px] mr-6px" /> {{ $t('zmbvS1ttTui9nZPJrO8tv') }}
          </AButton>
          <AButton class="custom-button" data-test-id="hty3" @click="showSellVip.setTrue()">
            <i class="icon i-local:sell-btn text-[22px] mr-6px" />{{ $t('lwo61KCcFg0pRYGRGmwyk') }}
          </AButton>
        </div>

        <CurrentOrder class="current-div" />
        <FastTrad v-if="!isConnected" class="trad-div row-start-4 row-end-5 lt-laptopl:hidden" />
      </div>
      <History />
    </div>

    <AModal
      :footer="null"
      :open="states.isConfirm && $bp.ltLaptopl"
      :closable="false"
      destroyOnClose
      :maskClosable="false"
      :zIndex="200"
    >
      <template #modalRender>
        <BuyPopup ref="BuyPopupRef" class="pointer-events-initial" />
      </template>
    </AModal>

    <AModal
      wrapClassName="ant-cover__basic-modal"
      :footer="null"
      :open="showSellVip.is"
      destroyOnClose
      :maskClosable="false"
      @cancel="showSellVip.setFalse()"
    >
      <SellVip />
    </AModal>

    <Announcement pos="5" />

    <ActivityModels />
  </div>
</template>

<style scoped lang="scss">
.c2c-content {
  z-index: 4 !important;
  margin: auto;

  // pc
  @media bp-laptopl {
    margin-top: 40px;

    .activity-box{
      grid-row: 1;
    }

    .sell-div {
      grid-row: 1 / 3;
    }

    .wallet-div {
      grid-row-start: 1;
    }

    .current-div {
      grid-row: 3 / 5;
    }

    .c2c-box {
      display: grid;
      grid-template-rows: 100px 100px 65px auto;
      grid-template-columns: 910px 1fr;
      gap: 30px;
    }

    .c2c-box2 {
      display: grid;
      grid-template-rows: 100px 100px 350px auto;
      grid-template-columns: 910px 1fr;
      gap: 30px;
    }
  }

  // 移动
  @media bp-lt-laptopl {
    .c2c-box, .c2c-box2 {
      display: grid;
      grid-template-columns: 100%;
      gap: 16px;

      .notif-div,
      .buy-div,
      .sell-div {
        display: none;
      }
    }

    .top-buttons{
      gap: 15px;

      .custom-button {
        --uno: 'flex-center';

        flex: 1;
        height: 50px;
        font-size: 18px;
        line-height: 50px;
        color: #dee5ef;
        background: linear-gradient(180deg, #2e3f74, #232c4f);
        border: none;
        border-radius: 10px;
      }
    }
  }

  .wallet-div{

  }
}

@media bp-lt-tabletl {
  :deep(.ant-empty .ant-empty-image) {
    height: 50px;
  }
}

:deep(.slick-arrow.custom-slick-arrow) {
  z-index: 1;
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgb(31 45 61 / 11%);
  opacity: 0.3;
  transition: ease all 0.3s;
}

:deep(.slick-arrow.custom-slick-arrow::before) {
  display: none;
}

:deep(.slick-arrow.custom-slick-arrow:hover) {
  color: #fff;
  opacity: 0.5;
}

:deep(.slick-slide h3) {
  color: #fff;
}
</style>
