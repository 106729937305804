<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { useC2cBuyMachine } from './machine/useC2cBuyMachine'
import { useBuy } from './hooks/useBuy'
import BuyTnfoConfirmation from './BuyTnfoConfirmation.vue'
import { EPaymentMethod } from '~/components/pay-type/src/PayType.vue'
import transfer from '~/assets/icons/transfer.svg'
import tronlink from '~/assets/icons/tronlink.svg'
import ttpay from '~/assets/icons/ttpay-s2.svg'
import { useUserInfo } from '~/composables/app'

interface IPayType {
  icon: string
  name: string
  code: number
  describe: string
}
const {
  machine,
  states,
  queryTronlinkOrder,
  queryTtpayOrder,
} = useC2cBuyMachine()
const { payType, showPayType } = useBuy()
const { refetch } = tronComp.useAccountCurrent()
const { userInfoQuerier } = useUserInfo()
const bus = useEventBus<string>('SWITCH-WALLET')

const dappTitle = computed(() => {
  const info = {
    title: $t('RCxTmhCLcxUJbWpMp1-7i'),
    subtitle: '',
  }
  if ([4, 5, 6, 15].includes(queryTronlinkOrder.value.code))
    info.title = $t('JYxhVrs9FRUVJT6UPetkz')

  if ([3].includes(queryTronlinkOrder.value.code)) {
    info.title = $t('1pSf-TKGPtKbuApOfRbRt')
    info.subtitle = $t('5qh1mLKZJRhuN2jfuToms')
  }
  return info
})

const ttpayTitle = computed(() => {
  const info = {
    title: '',
    subtitle: '',
  }
  if ([4, 5, 6, 15, 3].includes(queryTtpayOrder.value?.code))
    info.title = $t('1pSf-TKGPtKbuApOfRbRt')
  else if ([17025, 0].includes(queryTtpayOrder.value?.code))
    info.title = $t('-sBqqGY9_vH0DnctycEru')
  else if (queryTtpayOrder.value?.code === 12002)
    info.title = $t('-MjYAqdGMw1Jpq1bk-qXy')
  else
    info.title = $t('RCxTmhCLcxUJbWpMp1-7i')

  return info
})

const payTypeMap: IPayType[] = [
  {
    icon: tronlink,
    name: $t('eK8cI6D8Y586L2GRmnMuJ'),
    code: EPaymentMethod.DAPP,
    describe: $t('BZEju5c6ccIBGGMHehs66'),
  },
  {
    icon: transfer,
    name: $t('QnlQDhxPAdpU8ZziCOLhP'),
    code: EPaymentMethod.TRANSFER,
    describe: $t('ABBoTvHU7qTSdHy6N-e5t'),
  },
  {
    icon: ttpay,
    name: $t('rgpIJt75_6oaewUX8e0Rw'),
    code: EPaymentMethod.TTPAY,
    describe: $t('gTOmG5x9swaZKLRl53jDq'),
  },
]

async function balancePaymentCallback() {
  machine.send('OK')
  userInfoQuerier.refetch()
}

function dappPaymentCallback() {
  refetch()
  machine.send('OK')
}

// 监听切换钱包，关闭弹窗
bus.on(() => {
  machine.send('CLOSE')
})
</script>

<template>
  <!-- dapp支付结果 -->
  <AModal
    wrapClassName="ant-cover__basic-modal"
    :open="states.isTronlinkOrderResults"
    :footer="false"
    destroyOnClose
    :closable="false"
    :maskClosable="false"
    @cancel="dappPaymentCallback"
  >
    <OrderResult
      :icon="queryTronlinkOrder.status"
      :title="dappTitle?.title"
      :subTitle="dappTitle?.subtitle"
      @onClick="dappPaymentCallback"
    />
  </AModal>

  <!-- 轮询中弹窗 -->
  <AModal
    wrapClassName="ant-cover__basic-modal"
    :open="states.isQueryingTtpay"
    :footer="false"
    destroyOnClose
    :closable="false"
    :maskClosable="false"
    @cancel="machine.send('STOP')"
  >
    <div class="paying">
      <div>
        <i class="rocket i-local:loding animate-spin" />
      </div>
      <div class="content">
        <p class="title">
          {{ $t('scp62ZbqlEtRo8sjhuytQ') }}
        </p>
        <p>{{ $t('ZtHAGqOWfsToIfSa90Gsj') }}</p>
        <p>{{ $t('15Qm4JknRPEorJ-e0UK5V') }}</p>
      </div>

      <div class="footer">
        <button class="cancel-btn flex-1" data-test-id="5fwb" @click="machine.send('STOP')">  {{ $t("LVEIIOCshyC88EcfxCgMK") }}</button>
        <button class="comfirm-btn flex-1" data-test-id="2xgk" @click="machine.send('KEEP')"> {{ $t("mYVYrX3AMy1W_Lq6EvNWR") }}</button>
      </div>
    </div>
  </AModal>

  <!-- ttpay 支付结果 -->
  <AModal
    wrapClassName="ant-cover__basic-modal"
    :open="states.isTtpayOrderResults"
    :footer="null"
    destroyOnClose
    :maskClosable="false"
    :closable="false"
    @cancel="machine.send('OK')"
  >
    <OrderResult
      :icon="queryTtpayOrder?.status"
      :title="ttpayTitle?.title"
      @onClick="machine.send('OK')"
    />
  </AModal>

  <!-- 余额支付 支付结果 -->
  <AModal
    wrapClassName="ant-cover__basic-modal"
    :open="states.isBalanceResults"
    :footer="null"
    destroyOnClose
    :maskClosable="false"
    :closable="false"
    @cancel="balancePaymentCallback()"
  >
    <OrderResult
      icon="success"
      :title="$t('1pSf-TKGPtKbuApOfRbRt')"
      @onClick="balancePaymentCallback()"
    />
  </AModal>

  <AModal
    wrapClassName="ant-cover__basic-modal"
    destroyOnClose
    :closable="false"
    :footer="null"
    :maskClosable="false"
    :open="states.requesting"
  >
    <OrderCreate />
  </AModal>

  <!-- 租用信息确认框 -->
  <AModal
    wrapClassName="ant-cover__basic-modal"
    destroyOnClose
    :footer="null"
    :maskClosable="false"
    :open="states.isInfoConfirm"
    @cancel="machine.send('CLOSE')"
  >
    <BuyTnfoConfirmation />
  </AModal>
</template>

<style scoped lang="scss">
.paying {
  text-align: center;

  .rocket {
    --uno: 'text-80px lt-mobile:text-60px';
  }

  .content {
    p {
      font-size: 16px;
      line-height: 2;
      color: #8098BF;
    }

    .title {
      margin: 24px 0;
      font-size: 20px;
      color: #E2E8F2;
      text-align: center;
    }
  }

  .footer {
    display: flex;
    gap: 19px;
    justify-content: space-between;
    margin-top: 20px;

    * {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45%;
      height: 50px;
      padding: 20px 0;
      color: white;
      border-radius: 10px;
    }

    .cancel {
      background-color: #707582;
    }
  }
}

@media bp-lt-tablet {
  .paying {
    transform: scale(0.87);
  }
}
</style>
