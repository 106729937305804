<script lang="ts">
import FreezePopup from '../freeze/FreezePopup.vue'
import { useSellProvider } from '../../hooks/useSell'
import { useSell } from './hooks/index'
import { useC2cSellMachine } from './machine/useC2cSellMachine'
import { tron } from '~/utils/tron-v2'
import { useResource, useResourceTypeText } from '~/composables/tron'

export interface IProps {
  orderDetail: Api.GetDataItem<typeof apis.orderTrades>
  muitiSingAddress: string
}
</script>

<script setup lang="ts">
const props = defineProps<IProps>()
const emits = defineEmits(['update:muitiSingAddress'])
const { resourceType: ResourceType } = useResource()
const {
  formData,
  openFreezeModal,
  onSubmit,
  freezeModalDetail,
  resourceType,
  ranges,
  energyMin,
  sellInfo,
  closeFreeze,
  showSellRang,
  multiAddress,
  accountAddress,
  visibleFreeze,
  isMultiSign,
  spinning,
  sellForm,
} = useSell(props)
const { remaineResource, balance } = useSellProvider(
  toRef(isMultiSign, 'is'),
  multiAddress,
)
const { machine, states } = useC2cSellMachine()!
const resTypeText = useResourceTypeText(toRef(() => props.orderDetail.resource_type))

function onPayAddressChange() {
  const value = multiAddress.value

  if (!tron.isAddress(value))
    return

  spinning.setTrue()

  emits('update:muitiSingAddress', value)

  setTimeout(() => {
    spinning.setFalse()
  }, 500)
}

watch(isMultiSign, () => {
  multiAddress.value = ''
})
machine.service.onTransition((state) => {
  if (state.context.orderPreSubmitCode === 12008)
    openFreezeModal(0)
})
</script>

<template>
  <div>
    <AForm layout="vertical" class="z-form antd-cover__bubble-help-form" v-bind="sellForm.formProps">
      <AFormItem
        :label="$t('aPxHcAtUeRhinzGNtxYfs')"
        class="ant-cover__basic-form-item"
        v-bind="sellForm.formItemProps.sellAmount"
      >
        <AInputNumber
          v-model:value="formData.sellAmount"

          class="z-input-number"
          v-bind="inputMoney"
          :max="orderDetail.max_amount"
          :min="orderDetail.min_amount"
          :placeholder="`${$t('6I6ZsfCjiKlPnuBdfAXWl') + formatAmount(orderDetail.min_amount, 0)}~${formatAmount(orderDetail.max_amount, 0)})`"
          :disabled="orderDetail.max_amount == orderDetail.min_amount"
          style="width: 100%"
          :controls="false"
        >
          <template #prefix>
            <i
              v-if="resourceType === 'ENERGY'"
              class="prefix-icon i-local:energy-s2"
            />
            <i v-else class="prefix-icon i-local:tron-bw2-s2" />
          </template>
        </AInputNumber>
        <!-- 能量不足提示 -->
        <p
          v-if="formData.sellAmount > remaineResource[resourceType]"
          class="text-primary cursor-pointer"
          data-test-id="q0qq"
          @click="openFreezeModal(0)"
        >
          {{ $t('uInPSpuZAyKnXyUZxgl1u', {
            type: resTypeText,
          }) }}
          <span class="text-text-blue">
            {{ $t('FGp_xt7ZyK38zWPjOTYx1', { type: resTypeText }) }}
          </span>
        </p>
      </AFormItem>

      <AFormItem
        :labelCol="{ span: 24 }"
        class="ant-cover__basic-form-item"
        v-bind="sellForm.formItemProps.payAddress"
      >
        <template #label>
          <div class="flex-between">
            <div>{{ $t('Zdcch9ytExUXtd2Qyv-um') }}</div>
            <a v-if="!client.isMobile" class="text-text-blue" data-test-id="rqpw" @click="isMultiSign.toggle()">
              {{ isMultiSign.is ? $t('d4kgtk347B8Cjm97DeKcP') : $t('xFlr5vB5K5hAhdqnRR7KC') }}
            </a>
          </div>
        </template>
        <AInput
          v-if="isMultiSign.is"
          v-model:value="multiAddress"
          v-focus-right-directive
          type="text"
          style="width: 100%"
          autocomplete="off"
          data-test-id="KWD3"
          @change="onPayAddressChange"
        >
          <template #prefix>
            <i class="prefix-icon i-local:tron-wallet-s2" />
          </template>
        </AInput>
        <AInput
          v-if="!isMultiSign.is"
          v-focus-right-directive
          type="text"
          autocapitalize="no"
          disabled
          :value="accountAddress"
          style="width: 100%"
          data-test-id="r4YQ"
        >
          <template #prefix>
            <i class="prefix-icon i-local:tron-wallet-s2" />
          </template>
        </AInput>
      </AFormItem>

      <AFormItem
        :label="$t('hyjEhRcQGOmGctPs0H336')"
        class="ant-cover__basic-form-item"
        v-bind="sellForm.formItemProps.settleAddress"
      >
        <AInput
          v-model:value="formData.settleAddress"
          v-focus-right-directive
          type="text"
          :placeholder="$t('l1Aa6R6MIjs2WRyRykB3')"
          style="width: 100%"
          autocapitalize="off"
          data-test-id="tC7M"
        >
          <template #prefix>
            <i class="prefix-icon i-local:tron-wallet-s2" />
          </template>
        </AInput>
      </AFormItem>

      <div class="info ">
        <p>
          <span>
            {{ $t('wrV2-T8NBPh_gw9mflb8K', {
              type: resTypeText,
            }) }}
            <ATooltip
              class="z-tooltip" :title="`${$t('sZiNWEDkR6Ybae1EHGfew', {
                type: resTypeText,
              })}`"
            >
              <span><i class="icon" /></span>
            </ATooltip>
            ：
          </span>
          <span>
            {{ formatAmount(remaineResource[resourceType] ?? 0, 0) }}
            <a class="ml-2" data-test-id="rvax" @click="openFreezeModal(1)">
              {{ $t('AdmE9rLxTgTr1XTYMJXQt', { type: resTypeText }) }}</a>
          </span>
        </p>

        <p>
          <span>
            {{ $t('zZDOHRyzqCjG0QnsGDU_9') }}
            <ATooltip class="z-tooltip" :title="$t('p0DkoUiL9gQoaX5wRx67K')">
              <span><i class="icon" /></span>
            </ATooltip>
            ：
          </span>
          <span> {{ formatAmount(divide(balance, 1000000), 3) }} </span>
        </p>

        <p>
          <span>
            {{ $t('9L4M-dMjVyS8VpO2FEJdk') }}
            <ATooltip class="z-tooltip" :title="$t('aT88D6svaA6RNCrj5Be')">
              <span><i class="icon" /></span>
            </ATooltip>
            ：</span>
          <span>
            {{ timeFn(orderDetail.rent_duration, orderDetail.rent_time_unit) }}
          </span>
        </p>

        <p>
          <span>
            {{ $t('qq-vvv5UVJKhbK7U_-5xF') }}
            <ATooltip class="z-tooltip" :title="$t('tpf0iCIqfffXkt1ZCwcxU')">
              <span><i class="icon" /></span>
            </ATooltip>
            ：
          </span>
          <span><span class="text-text-green mr-2 font-bold">{{ formatAmount(sellInfo.amount, 3) }}</span> TRX</span>
        </p>
      </div>

      <AButton
        class="comfirm-btn w-100%"
        :loading="spinning.is"
        :disabled="formData.sellAmount > remaineResource[resourceType]"
        data-test-id="fsiv"
        @click="onSubmit"
      >
        {{ $t('lwo61KCcFg0pRYGRGmwyk') }}
      </AButton>
    </AForm>

    <AModal
      v-model:open="visibleFreeze.is"
      wrapClassName="ant-cover__basic-modal"
      destroyOnClose
      :footer="false"
    >
      <FreezePopup :detail="freezeModalDetail" :close="closeFreeze" />
    </AModal>
  </div>
</template>

<style lang="scss" scoped>
.info{
  --uno: 'mb-30px text-md text-primary';

  p{
    --uno: 'flex-between';

    span{
      --uno: 'flex-between';
    }
  }

  .icon{
    font-size: 18px;
  }
}
</style>
