<script setup lang="ts">
import { useAnimation } from '@/hooks/useAnimation'
import animationData from '~/assets/imgs/json/C2C-hj.json'

const { config } = useAppState()
const tradRef = ref<Element | null>(null)

useAnimation(tradRef, animationData)
</script>

<template>
  <div class="trad-box">
    <div class="trad">
      <h1 class="text-30px font-bold">
        {{ $t('syeMRIeBr3TkMUaTmUyEH') }}
        <p class="text-18px font-400 mt-6px">{{ $t('oduMMionIcpsZzCk4KfCv') }}</p>
      </h1>
      <ul class="text-#8098bf text-14px h-115px lh-24px mt-17px mb-20px">
        <li>{{ $t("hZmrfFgil-GMjpg5XjvK-") }}</li>
        <li>{{ $t("9AKzqTdGSjOISWkIssZVC") }}</li>
        <li>
          <template v-if="currentLocale === 'zh'">
            范围更大，支持{{
              formatAmount(divide(config?.order.energy_min_value, 10000) || 1, 4)
            }}
            万起租
          </template>
          <template v-else>
            Larger scope, rent starting at {{
              formatAmount(config?.order.energy_min_value || 10000, 4)
            }}
          </template>
        </li>
        <li>{{ $t('r6T6Tw1cYcQu7UzCTnfKy') }}</li>
      </ul>
      <a
        class="go-button"
        :href="localePath('/b2c')"
        data-test-id="AU4t"
      >
        Go
      </a>
      <div ref="tradRef" class="sell-img w-110px h-248px" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.trad-box{
  width: 100%;
  padding: 30px;
  color: #e2e8f2;
  background: #202C52;
  border-radius: 30px;

  .trad {
    display: grid;
    grid-template-rows: 1fr auto 0.5fr;
    grid-template-columns: 270px 1fr ;
    gap: 0 20px;
    align-items: center;
    justify-content: space-between;
    margin: auto 0;

    .go-button {
      --uno: 'text-20px font-bold text-center h-40px lh-40px';

      width: 170px;
      color: var(--first-text);
      background: #266ef1;
      border: none;
      border-radius: 10px;

      &:hover, &:active {
        background: linear-gradient(90deg,#2680EB,#00C1A5) no-repeat;
      }
    }

    .sell-img {
      grid-row: 1 / 4;
      grid-column-start: 2;
    }
  }
}
</style>
