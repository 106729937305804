<script setup lang="ts">
interface Color {
  from: string
  to: string
}

interface ProgressProp {
  status?: number
  showText?: boolean
  current?: number
  total?: number
  strokeColor?: Color
  trailColor?: string
  textColor?: string
  height?: number
  width?: number
}

const props = withDefaults(defineProps<ProgressProp>(), {
  strokeColor: {
    from: '#108ee9',
    to: '#266EF1',
  } as any,
  trailColor: '#c8d0df',
  textColor: '#266ef1',
  showText: true,
  status: -1,
})

function getPercent(current, total) {
  if (!current || !total)
    return 0
  return (current / total) * 100
}

const percent = computed(() => {
  return getPercent(props.current, props.total)
})
</script>

<template>
  <div v-if="props.status">
    <template v-if="status === 15">
      <div
        class="progress-container"
        :style="{
          width: `${width}px`,
        }"
      >
        <div
          v-if="props.showText"
          class="text"
          :style="{
            color: props.textColor,
          }"
        >
          <span class="status">{{ Math.floor(percent) }}%
            <span
              :style="{
                color: props.textColor,
              }"
              class="num"
            >({{ $t('lWvHr2BgZj08wMykrDLER') }}: {{ formatAmount(props?.current, 0) }})</span></span>
        </div>
        <AProgress
          class="progress"
          :strokeColor="props.strokeColor"
          :trailColor="props.trailColor"
          :strokeWidth="props.height"
          :showInfo="false"
          :percent="percent"
        />
      </div>
    </template>
    <template v-else>
      <span :style="{ color: props.status === 4 ? props.textColor : '' }">{{
        statusFilters(props.status) ?? $t('N4TMtCt6FHODhwt16sRT6')
      }}</span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.progress-container {
  display: grid;
  grid-template-rows: 12px 1fr;
  justify-items: center;
  margin: auto;
}

.wait {
  color: #266ef1;
}

.num {
  white-space: nowrap;
}

.status {
  white-space: nowrap;
}
</style>
