<script lang="ts">
import LatestTable from './LatestTable.vue'
import AgentTable from './AgentTable.vue'
import BuyTable from './BuyTable.vue'

export enum ETableType {
  Latest,
  Agent,
  Buy,
}
</script>

<script lang="ts" setup>
const activeKey = ref(ETableType.Latest)
</script>

<template>
  <div class="history-order-container relative">
    <ATabs v-model:activeKey="activeKey" class="ant-cover__basic-tabs" destroyInactiveTabPane>
      <ATabPane :key="ETableType.Latest" :tab="$t('GVcfe_Jcw8hbBkg5avuMv')">
        <LatestTable class="content" :type="activeKey" />
      </ATabPane>

      <ATabPane :key="ETableType.Agent" :tab="$t('19aOVy_2ejSzHj0GaCmPd')">
        <AgentTable class="content" :type="activeKey" />
      </ATabPane>

      <ATabPane :key="ETableType.Buy" :tab="$t('kS72IjThlhtPBvvfh8ycQ')">
        <BuyTable class="content" :type="activeKey" />
      </ATabPane>
    </ATabs>
  </div>
</template>

<style lang="scss" scoped>
.history-order-container {
  --padding: 30px;

  padding: 10px var(--padding) var(--padding);
  margin-bottom: var(--padding);
  background: var(--app-gradient-top-light-d);
  border-radius: var(--padding);

  @media bp-lt-tablet {
    --padding: 16px;
  }

  .content {
    --both-sides-padding: var(--padding);

    margin: 0 calc(-1 * var(--padding));
  }
}
</style>
